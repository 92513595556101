.login-form {
  background-color: #ffffff;
  margin-top: 25px;
  margin-bottom: 25px;
  border-radius: 10px;
}

.form-header {
    display: flex;
    padding: 30px 30px;
    border-radius: 10px 10px 0 0;
    border-width: 0 0 2px 0;
    border-style: solid;
    align-items: center;
    justify-content: space-between;
    font-family: "Poppins Medium", sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #fec25e;
}
.session-container {
    width: 100%;
    max-width: 460px;
    .text-wrapper {
        margin-bottom: 40px;
        text-align: center;
        font-size: 1.2em;
        color: #000000;
    }
}
.session-container,
.session-form {
    max-width: 600px;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3);
    @media #{$screen-lte-992px} {
        font-size: .7em;
        .button-login.button-session {
            margin-top: 15px;
        }
        .create-account {
            font-size: 32px;
            br { display: none; }
        }
        .create-account a,
        .password-forgot a {
            padding: 0 10px ;
            font-size: 15px !important;
        }
        .not-found {
            font-size: 16px;
        }
    }
    .head-logo {
        .head-logo-image {
            height: 45px;
        }
    }
    .mat-form-field {
        margin-bottom: 25px;
    }
    .center-button-wrapper {
        text-align: center;
        button {
            float: unset;
        }
    }
    input{
        color: #ffffff;
    }
    .order-info{
        padding: 30px 30px;
    }
    .info-text {
        margin: 20px 0;
        text-align: center;
        font-size: 20px;
    }
  .password-forgot{
    text-align: right;
    a {
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }
  .session-button {
    padding: 0 30px;
    border-radius: 5px;
  }

    .session-link {
        font-family: "Poppins Medium", sans-serif !important;
        font-size: 14px !important;
        font-weight: bold;
    }

  .create-account {
    a {
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }

  .agree-policy {
    font-size: 16px;
    a {
      text-decoration: underline;
      &:hover{
        text-decoration: none;
      }
    }
  }

  .password-strength{
    color: #ffffff;
    margin-bottom: 10px;
    height: 21px;
  }
}


.not-found,
.text-box {
  color: #616161;
  font-size: 20px;
  text-align: center;
  margin: 20px;
}

.egret-indigo .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.egret-indigo .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: transparent !important;
}

.mat-checkbox-background {
  border: 2px solid #8bfafc !important;
}

.mat-checkbox{
    font-family: 'Montserrat Regular', sans-serif !important;
    font-weight: 500 !important;
}


.rememberMe{
    font-size: 16px;
  color: #ffffff;
}

.recaptcha-container {
    min-height: 80px;
    text-align: center;
    margin: 15px 0;
    overflow: hidden;
}
.recaptcha-box {
}

.rc-anchor-dark.rc-anchor-normal{
  border: none !important;
}

.rc-anchor-dark{
  background: #3b4757 !important;
}

.resent-activation {
    margin-top: 20px;
}

.rememberme_container{
  display: flex;
  justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 60px;
}

.login_form_row {
  width: 100%;
    &.mat-form-field {
        &.mat-focused {
            .input-field-icon {
                color: #fe9900;
            }
        }
        &.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
        &.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-wrapper .mat-form-field-label {
            left: 30px;
        }
        .mat-form-field-infix,
        .mat-form-field-appearance-legacy  .mat-form-field-infix {
            display: flex;
            flex: 1 1 100%;
            max-width: 100%;
            width: 100%;
        }
    }
  .input-field-icon {
      position: absolute;
      left: 5px;
      transition: color .2s ease;
      z-index: 2;
      &.email {
          top: 7px;
      }
      &.password {
          top: 6px;
      }
  }

  input {
    padding-left: 31px;
  }
  label {
    padding-left: 30px;
      color: rgba(0, 0, 0, 0.54);
  }
  &.no-icon {
      label {
          padding-left: 0 !important;
      }
      input {
          padding-left: 0 !important;
      }
  }
}
.forgot-confirm-form {
    flex: 1 1 550px;
    max-width: 550px;
    .logo-icon {
        padding-top: unset;
        margin-right: 20px;
    }
}
.eye-icon {
    position: absolute;
    right: 10px;
    top: 8px;
    &.active {
        &:before {
            position: absolute;
            left: 0;
            top: 11px;
            content: "";
            width: 24px;
            border-top-width: 2px;
            border-bottom-width: 2px;
            border-top-style: solid;
            border-bottom-style: solid;
            transform: rotateZ(-45deg);
        }
    }
}
.lost-your-phone {
    color: white;
    margin-top: 10px;
    a {
        color: #337ab7 !important;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    @media #{$screen-lte-767px} {
        font-size: 12px;
    }
}

.payment-continue-button{
    margin-bottom: 20px !important;
    @include themify($themes) {
        background-color: themed(buyMain) !important;
        color: themed(selectBtnBg) !important;

        &:hover{
            background-color: themed(mainBtnBg) !important;
        }
    }
}
