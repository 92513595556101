#profile,
.profile-box {
  h3, h2, h1{
    margin: 0;
    color: #FFFFFF;
  }
  .user-verification {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    padding: 10px 0 10px 0;
    margin-top: 20px;
    margin-bottom: 20px;
  }

    .user-verification-bordered {
        padding: 15px;
        margin-bottom: 20px;
        // margin-top: 20px !important;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
        &.access-bordered,
        &.warning-bordered,
        &.info-bordered {
            margin-top: 0 !important;
            margin-bottom: 15px !important;
        }
        &.verification-box {
            text-align: center;
        }
    }
    .verify-step-title {
        padding: 0 0 15px;
        line-height: 22px;
        font-size: 18px;
    }

    .identify-doc-input {
        display: flex;
        flex: 1 1 100%;
        max-width: 200px;
        margin-bottom: 15px;
    }
  .button-auth {
    //background-color: #314456 !important;
    margin-top: 3px;
  }
  p{
    margin: 0;
  }
  .mat-select-value{
    color: #FFFFFF !important;
  }
}
.form-body.profile {
    margin-top: 15px;
}
.profile-form {
    .dob-field {
        position:relative;
        margin-top: 20px;
        @media #{$screen-lt-sm} {
            margin-top: 10px;
        }
        .dob-title {
            position: absolute;
            top: -20px;
            color: #ffffff;
            @media #{$screen-lt-sm} {
                position: static;
                flex: 1 1 100%;
                max-width: 100%;
                margin-bottom: 10px !important;
            }
        }
        .date-field {
            @media #{$screen-xxs} {
                flex: 1 1 100% !important;
                max-width: 100% !important;
                margin-left: 0 !important;
            }
        }
    }
    .gender-field {
        position: relative;
        margin-top: 10px;
        margin-bottom:20px;
        color:#ffffff;
        .gender-title {
            position:absolute;
            top: -24px;
            display: block;
            @media #{$screen-lt-sm} {
                position: static;
            }
        }

        .mat-radio-button {
            @media #{$screen-xxs} {
                flex: 1 1 100% !important;
                max-width: 100% !important;
                margin-left: 0 !important;
            }
        }
    }
}

.button-disapprove{
  background-color: $basic-red !important;
  color: #ffffff !important;
}

.disapprove-info{
  color: #8a8a8a;
  font-size: 14px;
}

.egret-indigo .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: #ffffff !important;
}

.api-key {
    background-color: #23375d;
    padding: 10px;
    margin-top: 10px;
    border-radius: 3px;
    color: transparent;
    text-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
    word-break: break-word;
}

.api-key:before {
    content: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjViNGYyZDIwMTYzZTM2MGFjMDk4ZTAyZCIsImlhdCI6MTUzMTkxNTU1Miwr9rtA9vzLFZ19d_Y7OuM4yKYY";
}

.submit-button.settings {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @media #{$screen-lte-767px} {
        margin-top: 15px;
    }
    .save-button {
        margin-left: 15px;
    }
}

.referer-information {
    margin-top: 25px;
}

.info-kyc {
    margin-bottom: 20px;
}

.enable-disable-notification-btn {
  margin-right: 10px !important;
}

.verification-steps {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
    img {
        width: 200px;
    }
}