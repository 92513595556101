body,
html {
    margin: 0;
    padding: 0;
}

body {
    font-size: 12px;
    font-family: 'Poppins Light', sans-serif !important;
    font-weight: 300 !important;
    letter-spacing: 1.05px !important;
    color: #67757c;
}

p {
    margin: 0 !important;
}

.user-body {}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

app-main-layout {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: stretch;
}

.wrapper {
    flex: 1 0 auto;
    padding-top: 5px;
    transition: background-color .3s ease;
    >*:not(router-outlet) {
        display: block;
        max-width: 100%;
    }
}

.page-flex-wrapper {
    display: flex;
    flex: 1 1 100%;
    max-width: 100%;
    justify-content: center;
    align-items: flex-start;
}

.page-container {
    display: flex;
    flex: 1 1 100%;
    max-width: 1280px;
    flex-flow: row wrap;
    margin: 0 25px !important;
    justify-content: center;
    align-items: flex-start;
    @media #{$screen-lt-lg} {
        max-width: 960px;
    }
}

.sessions-main-container {
    display: flex;
    flex-flow: row nowrap;
    // height: calc(100vh - 130px);
    margin: 40px auto;
    justify-content: center;
    align-items: center;
}

header,
.user_header,
footer {
    transition: background-color .3s ease;
}

.header-text {
    font-size: 18px;
    // margin-bottom: 10px;
    // color: #ffffff;
    @media #{$screen-lt-sm} {
        font-size: 14px !important;
        line-height: 17px !important;
    }
}

.header-container,
.footer-container {
    @media #{$screen-lte-850px} {
        flex-direction: column;
    }
    .footer-left-side {
        @media #{$screen-lte-850px} {
            order: 2;
            flex-direction: column;
        }
    }
    .logo {
        display: flex;
        align-items: center;
        float: left;
        min-width: 80px;
        margin-right: 30px;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center center;
        @media #{$screen-lte-669px} {
            margin-right: unset;
            a {
                padding: 8px 0;
                line-height: 35px;
            }
        }
    }
}

.header-container .head-logo {
    min-width: 120px;
    height: 100%;
}

.header-container {
    .header-menu-container {
        display: flex;
        //.right-menu {
        //    flex: 30%;
        //}
        .left-menu-container {
            flex: 100;
        }
    }
    .head-logo {
        min-width: 120px;
        height: 60px;
        cursor: pointer;
        @media #{$screen-lte-669px} {
            min-width: 80px;
        }
        img {
            padding: 0;
            @media #{$screen-lte-669px} {
                height: auto;
                margin: 8px 0;
                width: 70px;
            }
        }
    }
    .head-menu {
        max-height: 60px;
        float: left;
        line-height: 58px;
        .sessions-menu {
            div a {
                font-size: 14px !important;
            }
        }
        div {
            display: flex;
            margin-right: 12px;
            align-items: center;
            font-size: 12px;
            a {
                letter-spacing: 0.4px;
                transition: all 0.2s ease;
                &:hover {
                    text-decoration: none;
                }
                &:focus {
                    text-decoration: none;
                }
                i {
                    float: left;
                    margin-right: 8px;
                    font-size: 22px;
                    line-height: 58px;
                    transition: all 0.2s ease;
                }
            }
        }
    }
    .user-panel {
        .profile-bar {
            padding-left: 0;
            padding-right: 15px;
            .user-profile-toggle {
                display: flex;
                align-items: center;
            }
            .nav>li>a {
                font-size: 13px;
                @media #{$screen-lte-992px} {
                    &.user-profile-toggle {
                        padding-top: 12px !important;
                    }
                    &.language-flag-toggle {
                        padding-top: 11px !important;
                    }
                }
                @media #{$screen-lte-768px} {
                    padding: 10px 8px !important;
                    &.user-profile-toggle {
                        padding-top: 7px !important;
                    }
                    &.language-flag-toggle {
                        padding-top: 6px !important;
                    }
                }
            }
            @media #{$screen-lte-768px} {
                padding-left: 0 !important;
                padding-right: 0 !important;
                .navbar-nav {
                    margin: 7.5px 0 !important;
                }
            }
        }
        float: right;
        line-height: 60px;
        display: flex;
        .item {
            display: inline-block;
            margin-left: 15px;
            a {
                color: #ffffff;
                transition: all 0.1s ease;
                &:hover {
                    text-decoration: none;
                    color: $basic-red !important;
                }
                &.active {
                    color: $basic-red !important;
                }
            }
        }
        .hamburger-menu {
            @media #{$screen-lte-768px} {
                padding-right: 0 !important;
                padding-left: 0 !important;
            }
        }
        #language_bar {
            padding-right: 0;
            .caret {
                margin-left: 5px;
            }
            .dropdown-toggle {
                @media #{$screen-lte-768px} {
                    padding-right: 6px !important;
                    padding-left: 6px !important;
                }
            }
            .dropdown-menu {
                padding: 0 0 !important;
                .language {
                    cursor: pointer;
                }
            }
            li {
                img {
                    width: 30px !important;
                    height: 30px !important;
                }
            }
        }
        .profile-bar {
            &:hover {
                .img-thumbnail {
                    /*background-color: $main-bg-color-active;
          border-color: $main-bg-color-active;*/
                }
            }
            .caret {
                margin-left: 5px;
            }
            .profile-icon {
                display: inline-block;
                width: 22px;
                height: 29px;
                margin-right: 5px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
            }
            .dropdown-menu {
                padding: 0 0 !important;
            }
            li {
                img {
                    width: 30px !important;
                }
            }
        }
        .dropdown-menu {
            li {
                background-color: $main-bg-color-active;
                &:hover {
                    background-color: #425b73;
                }
            }
            &.profile-menu {
                li {
                    height: 36px;
                    a {
                        padding: 8px 20px;
                    }
                }
            }
        }
    }
    .theme-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 50px;
        .theme-icon {
            position: relative;
            top: 6px;
            display: flex;
            height: 22px;
            width: 22px;
            margin-right: 5px;
            background-color: transparent;
            border: unset;
            line-height: 0;
            cursor: pointer;
            outline: none;
            &.dark {
                .material-icons {
                    top: 1px;
                    transform: rotate(145deg);
                }
            }
            &.active {
                display: none;
                .material-icons {
                    color: #fec25e;
                }
            }
            .material-icons {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 22px;
                line-height: 22px;
                color: #ffffff;
            }
        }
    }
}

.caret {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
}

.material-icons {
    font-family: 'Material Icons', sans-serif !important;
    text-transform: lowercase !important;
}

iframe.header-chart-panel-sizer {
    background-color: white !important;
}

.theme-menu-panel {
    position: relative;
    top: 20px;
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration: none;
    background-color: transparent !important;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: transparent !important;
}

.dropdown-menu {
    background-color: transparent;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    background-color: transparent !important;
}

.img-thumbnail {
    background-color: transparent !important;
    border: 1px solid transparent !important;
}

.content {
    // margin-bottom: 20px;
}

.break-word {
    word-wrap: break-word;
    word-break: break-word;
}

iframe {
    width: 100%;
    height: 450px;
    border: 0;
    overflow: hidden;
}

footer {
    height: 64px;
    padding: 0 15px;
    @media screen and (max-width: 959.98px) {
        height: auto;
        min-height: 64px;
        padding: 10px 15px;
    }
    .footer-container {
        height: 64px;
        @media screen and (max-width: 959.98px) {
            height: auto;
            min-height: 64px;
        }
    }
    .logo-wrapper {
        width: 120px;
        height: 40px;
    }
    .logo {
        width: 100%;
        height: auto;
    }
    .footer-link {
        padding: .715rem 1.072rem;
        font-size: 13px;
        text-decoration: none;
    }
    .copyright-box {
        padding: 0 15px;
        font-size: 13px;
        p {
            text-align: center;
            margin: unset;
        }
        span {
            color: #26dfc8;
        }
    }
    .footer-logo {
        margin-top: 15px;
        img {
            height: 40px;
        }
    }
    .footer-menu-list {
        @media #{$screen-lte-767px} {
            margin: 10px 0 20px 0;
        }
    }
    .footer-menu {
        text-align: right;
        line-height: 25px;
        @media #{$screen-lt-sm} {
            flex-direction: column;
        }
        @media #{$screen-lte-767px} {
            .footer-menu-item {
                margin-top: 10px;
            }
        }
        .footer-menu-item {
            display: inline-block;
            margin-right: 15px;
            font-size: 13px;
            a {
                transition: all 0.1s ease;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.logo-icon {
    padding-top: 10px;
    text-align: center;
    img {
        height: 60px;
    }
}

.cancel-order {
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0;
    text-transform: uppercase;
    &.row-button {
        line-height: 28px !important;
    }
    &:hover {
        text-decoration: none;
    }
}

.cancel-all-box {
    margin: 12px 20px;
}

.button-cancel-all {
    padding: 0 16px;
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 0;
    text-decoration: none !important;
    text-transform: uppercase;
    outline: none;
}

.egret-indigo {
    .mat-select-content,
    .mat-select-panel-done-animating {
        // background: transparent !important;
    }
}

.mat-icon-button {
    .mat-button-wrapper {
        color: #ffffff;
    }
}

.egret-indigo {
    .mat-calendar-body-selected {
        box-shadow: none !important;
    }
    .mat-select-arrow {
        color: white !important;
    }
}

.pagination mat-paginator {
    .mat-paginator-page-size {
        flex: 1 0 auto;
        max-width: unset;
    }
    .mat-icon-button {
        height: auto;
    }
    @media #{$screen-lt-sm} {
        .mat-paginator-page-size,
        .mat-paginator-page-size-label {
            font-size: 1rem;
        }
    }
}

.horizontal-scrollable-container {
    overflow-x: scroll;
}

.vertically-scrollable {
    overflow-x: hidden;
    overflow-y: auto;
}

.mobile-full-flex {
    @media #{$screen-xxs} {
        max-width: 100%;
        width: 100%;
        margin-left: 0 !important;
    }
}

.cdk-overlay-pane-select-search {
    margin-top: 0 !important;
}

.mat-select-search-inner {
    .mat-input-element {
        caret-color: #000000 !important;
    }
}

.mat-datepicker-content {
    color: #fff !important;
}

.mat-calendar-arrow {
    border-top-color: #fff !important;
}

.egret-indigo .mat-datepicker-toggle,
.egret-indigo .mat-calendar-body-cell-content,
.egret-indigo .mat-calendar-body-label,
.egret-indigo .mat-datepicker-content .mat-calendar-next-button,
.egret-indigo .mat-datepicker-content .mat-calendar-previous-button,
.egret-indigo .mat-calendar-table-header {
    color: #fff !important;
}

.mat-select-search-no-entries-found {
    background-color: #314456;
    color: #fff;
}

.mat-form-field-wrapper {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 100%;
    max-width: 100%;
}

.mat-menu-panel,
.mat-select-panel {
    @include colorize(background-color, dropdownItemBg, !important);
}

.mat-select-panel mat-option.mat-option,
.mat-option {
    @include colorize(background-color, dropdownItemBg, !important);
    &.mat-active, &:hover, &.mat-selected:not(.mat-option-multiple) {
        @include colorize(background-color, dropdownItemBgHover, !important);
    }
    .mat-option-text {
        @include colorize(color, dropdownTxt, !important);
        &:hover {
            @include colorize(color, dropdownTxtHover, !important);
        }
    }
}

.mat-menu-item {
    @include colorize(color, dropdownTxt, !important);
    &:hover {
        @include colorize(color, dropdownTxtHover, !important);
    }
}

.scrollable-popup {
    max-height: 80vh;
    overflow-y: scroll;
    padding: 15px 10px 15px 0;
}

app-user-header,
app-admin-header,
app-kyc-admin-header {
    display: flex;
    .logo {
        display: flex;
        flex: 0 1 auto;
    }
    .head-menu {
        a {
            font-size: 13px !important;
        }
        display: flex;
        flex: 1 1 auto;
        app-responsive-menu {
            display: flex;
            flex: 1 1 100%;
            #menu-header {
                flex: 1 1 100%;
            }
        }
    }
}

#menu-header {
    margin: 0;
}

.responsive-table {
    width: 100%;
    max-height: 407px;
    overflow-y: auto;
    margin: 1rem 0;
    table {
        width: 100%;
    }
}

.main-table-head {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    font-family: "Poppins Medium", sans-serif !important;
    font-weight: bold;
    th {
        font-size: 13px;
        padding: 10px 5px;
        line-height: 18px;
        &.sortable {
            &:hover {
                cursor: pointer;
            }
        }
        &:last-of-type {
            text-align: right;
        }
    }
}

.main-table-row {
    font-size: 13px !important;
    line-height: 18px;
    border-bottom: 1px solid;
    td {
        &:last-of-type {
            padding-right: 5px !important;
            text-align: right;
        }
    }
}

.col-xs-auto {
    @media screen and (max-width: 767px) {
        width: auto;
        padding: 0;
    }
}

.col-sm-auto {
    @media screen and (min-width: 768px) {
        width: auto;
        padding: 0;
    }
}

.col-md-auto {
    @media screen and (min-width: 992px) {
        width: auto;
        padding: 0;
    }
}

.col-lg-auto {
    @media screen and (min-width: 1200px) {
        width: auto;
        padding: 0;
    }
}

.no-padding {
    padding: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.container-header {
    border-bottom: 1px solid;
    display: flex;
    flex-flow: row nowrap;
    padding: 0 20px 10px;
    margin: 0 -10px 10px -10px;
    justify-content: space-between;
    align-items: center;
}

.select-widget {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16);
    &.selectable {
        transition: box-shadow 135ms cubic-bezier(.4, 0, .2, 1);
        &:hover {
            box-shadow: 0 1px 3px 1px rgba(60, 64, 67, .2), 0 2px 8px 4px rgba(60, 64, 67, .1);
        }
    }
}

.widget-frame {
    border: 1px solid;
    border-radius: 5px;
}

.widget-container {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid;
    border-radius: 4px;
    &.selectable {
        &:hover {
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px -2px rgba(0, 0, 0, 0.2);
        }
    }
}

.show-all-button {
    font-size: 12px;
    padding: 0 16px;
    border-radius: 4px;
    text-decoration: none;
    border: 1px solid;
    background-color: transparent;
    text-transform: uppercase;
    line-height: 28px;
    &:hover {
        text-decoration: none;
    }
}

.button-main-shadow,
button.shadowed {
    box-shadow: 0 1px 1px 0 rgba(60, 64, 67, .08), 0 1px 3px 1px rgba(60, 64, 67, .16) !important;
    transition: box-shadow 135ms cubic-bezier(.4, 0, .2, 1), width 235ms cubic-bezier(.4, 0, .2, 1);
    &:hover {
        box-shadow: 0 1px 3px 1px rgba(60, 64, 67, .2), 0 2px 8px 4px rgba(60, 64, 67, .1) !important;
    }
}

.no-data-box {
    display: flex;
    flex: 1 1 100%;
    margin: 10px 0;
    padding: 10px 8px !important;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    background-color: transparent;
    text-align: center;
}

app-user-header {
    app-responsive-menu {
        .sessions-menu {
            justify-content: flex-end;
        }
    }
}

.see-details,
.edit-button {
    cursor: pointer;
    text-align: center;
    padding: 0 6px !important;
    border-radius: 5px !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: 11px !important;
    line-height: 26px !important;
    letter-spacing: 0;
    text-decoration: none !important;
    text-transform: uppercase;
    background: transparent !important;
    &:hover {
        text-decoration: none;
    }
    @media #{$screen-lte-1200px} {
        display: inline-block;
        padding: 0 3px !important;
        font-size: 8px;
        line-height: 12px;
    }
}

button,
[class*="button"] {
    &.tall-button {
        line-height: 36px !important;
    }
}

.button-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.button-primary {
    border-radius: 5px !important;
    justify-content: center;
}

.search-button {
    line-height: 34px !important;
}

.downloader-panel {
    display: none;
}

@media (max-width: 767px) and (min-width: 0px) {
    .downloader-panel {
        display: block;
    }
    .download-app {
        display: flex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #F4F4F4;
        z-index: 100000;
        align-items: center;
        padding: 5px;
        -webkit-box-shadow: 0px 3px 5px 0px rgba(125, 125, 125, 1);
        -moz-box-shadow: 0px 3px 5px 0px rgba(125, 125, 125, 1);
        box-shadow: 0px 3px 5px 0px rgba(125, 125, 125, 1);
    }
    .download-app .close-download {
        margin: 0 10px;
        background-color: #ffffff;
        padding: 13px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .download-app .image {
        width: 55%;
    }
    .download-app .image img {
        max-height: 60px;
    }
    .download-app .download-button {
        background-color: #FDC166;
        border-radius: 5px;
        margin-right: 10px;
        padding: 5px 15px;
        margin-left: auto;
        font-weight: bold;
    }
    .download-app .download-button a {
        color: #293348;
    }
}
