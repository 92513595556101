@media only screen and (min-width: 769px) {
    .popover {
        top: 0 !important;
    }
}

@media only screen and (max-width: 1195px) {
    .head-menu-item {
        font-size: 10px !important;
        margin-right: 11px !important;
    }
}

@media only screen and (max-width: 1130px) {
    #lanNavSel {
        display: none;
    }
    .dropdown {
        #name {
            display: none;
        }
    }

    .header-container .user-panel #language_bar li img {
        width: 29px !important;
    }
    .header-container .user-panel .profile-bar li img {
        width: 29px !important;
    }

    .head-menu {
        &.admin-menu {
            .head-menu-item {
                font-size: 14px;
            }
        }
    }

}

@media only screen and (max-width: 993px) {
    .fixed {
        width: 33.33333333%;
    }
    .hidden-menu {
        display: none;
    }
}

@media only screen and (max-width: 768px) {
    .fixed {
        position: inherit;
        padding-right: 0;
        width: 100%;
        top: 0;
    }
}

@media only screen and (max-width: 768px) {
    .sell-orders-title {
        margin-top: 25px;
    }
    .dropdown-menu {
        position: absolute !important;
        right: 0 !important;
        left: auto;
    }
    .nav > li > a {
        padding: 10px 12px !important;
    }
    .hamburger-menu {
        padding-left: 10px !important;
    }
    popover-content {
        position: initial;
    }
    popover-content {
        .popover {
            width: 100% !important;;
            min-width: 100% !important;
            left: 11px !important;
            margin-top: 255px !important;
        }
    }
}

@media only screen and (max-width: 500px) {
    a {
        padding: 10px 10px;
    }
    .footer-menu-item {
        a {
            padding: 0;
        }
    }
    .verification-notification :not(.user-menu) {
        display: none;
    }
}

@media (min-width: 0px) {
    .navbar-right {
        float: right !important;
    }

}

@media (min-width: 0px) {
    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
        border-top: 0px solid transparent;
        -webkit-box-shadow: inset 0 0 0 rgba(255, 255, 255, .1);
        box-shadow: inset 0 0 0 rgba(255, 255, 255, .1);
    }
}

header {
    .user-panel {
        .hamburger-menu {
            display: none !important;
            padding-right: 15px !important;
        }

        .profile-bar {
            padding-right: 0 !important;
        }
    }
}

@media only screen and (max-width: 992px) {
    header {
        .user-panel {
            .hamburger-menu {
                display: block !important;
                padding-right: 10px !important;
            }

            .profile-bar {
                padding-right: 15px !important;
            }

            .login-layout {
                display: none !important;
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    header {
        .head-logo {
            max-width: unset !important;
        }
    }
}

@media only screen and (max-width: 790px) {
    .user-panel {
        height: 60px !important;
    }
}

.main-app-container {
    .buy-orders-table,
    .sell-orders-table {
        .bid-tr {
            td {
                @media only screen and (max-width: 1600px) {
                    font-size: 1rem !important;
                }
                @media only screen and (min-width: 992px) and (max-width: 1300px) {
                    //font-size: .65rem !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 1420px) {
    .main-app-container {
        .buy-orders-title,
        .sell-orders-title {
            font-size: 13px !important;
            letter-spacing: 0;

            b {
                font-size: 11px;
                line-height: 19px;
            }
        }

        .buy-orders-table,
        .sell-orders-table {
            .table-head {
                th {
                    font-size: 13px;
                }
            }

            .table-body,
            .bid-tr {
                td {
                    font-size: 9px;
                }
            }
        }

        .sell-buy-bids {
            letter-spacing: 0;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .main-app-container {
        .buy-orders-title,
        .sell-orders-title {
            font-size: 12px !important;
            letter-spacing: 0;

            b {
                font-size: 9px;
                line-height: 19px;
            }
        }
    }
}

@media only screen and (max-width: 1110px) {
    .header-container {
        .head-menu {
            > div {
                a {
                    i {
                        display: none;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .head-menu {
        &.admin-menu {
            .material-icons {
                display: none;
            }
        }
    }
}

@media only screen and (max-width: 1042px) {
    .logo {
        &.admin-menu {
            margin-right: 15px;
        }
    }
    .head-menu {
        &.admin-menu {
            .head-menu-item {
                margin-right: 15px;
            }
        }
    }
}

@media only screen and (min-width: 1300px) {
    .main-app-container .market-history .bid-tr td {
        //  font-size: 12px;
    }
}


@media only screen and (max-width: 1200px) {
    .table.font-normal .table-body .table-row {
        font-size: 10px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .expanded-mode {
        padding-right: 0;
    }
}


//.expanded-mode-flex {
//    display: flex;
//    .expanded-mode {
//        display: flex;
//        flex-direction: column;
//    }
//    .right-col {
//        display: flex;
//        >div {
//            width: 100%;
//        }
//        .market-history {
//            max-height: 600px;
//            min-height: unset;
//        }
//    }
//}
