$basic-red: #f92552;
$basic-blue: #8bfafc;
$basic-green: #00c155;
$basic-green-opacity: unquote('#00c1557d');
// $basic-blue: #0066dc;
$body-bg: #343444;
$main-bg-color: #ffffff;
$main-bg-color-active: #314456;
$search-field-inner: #99b0db;
$blue-button: #365caf;
$wallets-table-border: #99b0db4f;

$colors: (
    main-bg-color-active: #314456,
    mat-active-option: #49647f,
    white: #ffffff,
)
