.cdk-overlay-pane {
        &.small-dialog {
        .mat-dialog-container {
            max-width: 450px !important;
        }
    }
}
.mat-dialog-container {
    display: block !important;
    padding: 20px !important;
    border-radius: 5px !important;
    overflow-y: auto !important;
}
.dialog-container {
    margin: 20px 0 0 0;
    .header-box {
        padding: 0 20px 20px !important;
        margin: 0 0 10px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }
    .content-box,
    .actions-box {
        margin: 10px 0;
        padding: 10px 20px;
    }
    .content-box {
        max-height: 50vh;
        overflow-y: auto;
    }
    .text-box {
        padding: 10px 0;
        margin: 0 0 10px;
        text-align: start;
    }
    .content-text {
        font-size: 14px;
        line-height: 18px;
    }
}
