.wallets {
    padding-top: 15px;
    @media only screen and (max-width: 991px) {
        width: 90% !important;
        margin: 0 auto;
    }

    &.no-top-padding {
        padding-top: 0;
    }

    .container-header {
        @media #{$screen-lte-479px} {
            flex-flow: row wrap;
        }
        .container-title {
            @media #{$screen-lte-479px} {
                margin-bottom: 10px;
            }
        }
        .box-buttons {
            @media #{$screen-lte-479px} {
                margin-left: 0;
            }
        }
    }
    .header {
        font-size: 18px;
        color: #ffffff;
        padding: 10px;
        border-bottom: 1px solid $wallets-table-border;
    }

    .checkbox-label-wordwrap {
        word-wrap: break-word;
        white-space: pre-wrap !important;
        line-height: 12px;
    }

    .estimated_balance {
        font-size: 12px;
        padding: 10px 0 0 10px;
    }

    .estimated {
        margin-bottom: 15px;
    }

    .wallets-filter {
        display: flex;
        align-items: center;
    }

    .referral_earnings {
        padding: 10px 10px 0 0;
        font-size: 12px;
        text-align: right;
        @media screen and (min-width: 0px) and (max-width: 991px) {
            text-align: left;
            padding: 10px 0 0 10px;
        }
    }

    .filter_by_balance {
        .mat-checkbox-layout {
            float: right;
            padding: 8px 0 0 0;
            display: flex;
            align-items: center;
        }
    }

    .estimated_balance,
    .filter_by_balance {
        @media screen and (min-width: 768px) and (max-width: 991px) {
            font-size: 1em;
        }
        @media #{$screen-lte-767px} {
            font-size: 0.9em;
        }
    }

    .wallet_header {
        background-color: #303045;
        border-radius: 3px;
        margin: 10px 10px;
        padding: 10px;
        color: #ffffff;

        &.orders {
            font-size: 12px;

            .last-col {
                text-align: right;
            }

            .sell {
                color: $basic-red;
            }

            .buy {
                color: $basic-green;
            }
        }

        .last-col {
            text-align: right;
        }
    }

    .wallet-action-header {
        color: #ffffff;
        font-size: 17px;
        &.wallet-title {
            margin-left: 7px;
        }
    }

    .wallet {
        background-color: $main-bg-color-active;
        border-radius: 3px;
        margin: 10px 10px;
        padding: 10px;
        color: #ffffff;

        .wallet-icon {
            height: 16px;
            margin-right: 7px;
            margin-top: -3px;
        }

        &.orders {
            font-size: 11px;

            .last-col {
                text-align: right;
            }

            .sell {
                color: $basic-red;
            }

            .buy {
                color: $basic-green;
            }
        }

        .address-qr-code {
            img {
                width: 100%;
                height: auto;
            }
        }

        .last-col {
            text-align: right;
        }

        .plus {
            color: $basic-green;
        }

        .minus {
            color: $basic-red;
        }

        .material-icons {
            cursor: pointer;
            font-size: 18px;

            &:hover {
                color: #cccccc;
            }

            &.deposit {
                color: $basic-green;

                &:hover {
                    color: #5fdb17;
                }
            }

            &.withdraw {
                color: $basic-red;

                &:hover {
                    color: #e63c5a;
                }
            }
        }
    }

    &.one-wallet {
        padding: 15px;

        .header {
            &.position-history {
                padding: 0;
                margin: 10px;
                @media #{$screen-lt-md} {
                    padding: 10px;
                    margin: 0;
                }
            }

            font-size: 22px;
            width: 100%;

            .last_24 {
                font-size: 14px;
            }
        }

        .pagination {
            margin: 1%;
            width: 98%;

            mat-paginator {
                float: right;

                .mat-paginator-decrement {
                    border-color: rgba(255, 255, 255, 0.38);
                }

                .mat-paginator-increment {
                    border-color: rgba(255, 255, 255, 0.38);
                }
            }
        }

        .plus {
            color: $basic-green;
        }

        .minus {
            color: $basic-red;
        }

        .export-csv {
            padding: 0;
            margin-bottom: 40px;
            @media #{$screen-lte-991px} {
               margin-right: 0;
            }

            .buttons {
                padding: 0 10px;

                .green {
                    &.dashboard-csv {
                        padding: 0 12px !important;
                    }

                    border: #314456 1px solid;

                    &:hover {
                        border: $basic-blue 1px solid;
                    }
                }

                .red {
                    border: $basic-red 1px solid;

                    &:hover {
                        border: $basic-red 1px solid;
                    }
                }

                float: right;
                color: #ffffff;

                span {
                    display: flex !important;
                    background-color: $main-bg-color-active;
                    padding: 6px 12px;
                    justify-content: center;
                    border-radius: 3px;
                    cursor: pointer;

                    @media #{$screen-lt-md} {
                        font-size: 10px;
                    }
                }
            }

            &.positions-export {
                margin: 15px 0 !important;
            }
        }


        .filters {
            &.position-history {
                margin: 15px 10px 0;
                border-bottom: 1px solid #293a5f;
                @media #{$screen-lt-md} {
                    padding: 10px 0;
                    margin: 0;
                }
            }

            &.email-search {
                margin-top: 15px;
                padding: 0 15px;
                @media #{$screen-lt-sm} {
                    padding: unset;
                }
            }

            .hide-cancelled {
                font-size: 11px;
                @media #{$screen-lte-991px} {
                    font-size: 15px;
                }
            }
        }

        .header {
            .export-csv {
                padding: 0px;
                float: right;
                margin-bottom: 0px;
                margin-right: 0px;

                .buttons {
                    display: flex;

                    .green {
                        border: #314456 1px solid;
                        font-size: 12px;

                        &:hover {
                            border: $basic-blue 1px solid;
                        }
                    }

                    .red {
                        border: $basic-red 1px solid;
                        font-size: 12px;

                        &:hover {
                            border: $basic-red 1px solid;
                        }
                    }

                    float: right;
                    color: #ffffff;

                    span {
                        display: inline-block !important;
                        background-color: $main-bg-color-active;
                        padding: 6px 12px;
                        border-radius: 3px;
                        cursor: pointer;

                        &:first-child {
                            margin-left: 12px;
                        }
                    }
                }
            }
        }


        .info {
            padding: 10px;
            color: #ffffff;
            font-size: 19px;
            @media #{$screen-lt-md} {
                font-size: 12px;
            }

            b {
                float: right;
            }
        }

        .balance {
            font-size: 14px;

            b {
                @include themify($themes) {
                    color: themed(selectBtnTxt);
                }
                font-weight: bold;
            }
        }

        .btn-unique {
            float: right;
            margin-bottom: 15px;
        }
    }
}

.wallet-funds-container {
    display: flex;
    flex-flow: row wrap;
    box-sizing: border-box;
    margin: 10px 0;
    padding: 0 15px;
    width: 100%;
    &.wallet-funds-box {
        padding: 0 5px 0 5px;
        margin-left: 0;
        margin-right: 2px;
    }
    .txt-wallet {
        padding: 0 0 0 3px
    }

    .address-qr-code {
        text-align: center;
    }

    .send-for-container {
        color: #ffffff;

        .title {
            margin-bottom: 20px;
            text-align: left !important;
            @media #{$screen-lt-sm} {
                margin-top: 20px;
                font-size: 14px;
            }
        }

        .wallet-address-form-field {
            margin-bottom: 15px;

            textarea {
                padding: 7px;
            }
        }
    }

    .notification-for-container {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        padding: 0 0 20px 20px;
        @media #{$screen-xs} {
            padding-left: 0;
        }

        .user-verification-bordered {
            display: flex;
        }

        .notification {
            display: flex;
            text-align: left;
            border: 1px solid;
            border-radius: 4px;

            p {
                margin-bottom: unset;
                line-height: 1.6em;
            }

            @media #{$screen-lt-sm} {
                margin: 15px 0;
            }
        }
    }
}

.table-wallet {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.wallets-table {
    .table-body .table-col {
        margin-top: unset !important;
        @media #{$screen-lt-md} {
            justify-content: space-between;
            margin-left: unset !important;
        }
    }
}

.table-box {
    max-height: 280px;
    overflow-y: scroll;
}

.table-box-row {
    padding: 5px 10px;
}

.deposit-withdraw-box {
    display: flex;
    flex: 1 0 auto;
    height: 26px;
    justify-content: flex-end;
}

.green {
    border: #314456 1px solid;

    &:hover {
        border: $basic-blue 1px solid;
    }
}

.red {
    border: $basic-red 1px solid;

    &:hover {
        border: $basic-red 1px solid;
    }
}


.mat-datepicker-toggle-default-icon {
    color: #ffffff;
}

.mat-select-value-text {
    color: #ffffff;
}

.padding-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.payment-methods {
    @include themify($themes) {
        background-color: themed(modalBg);
    }
    display: flex;
    align-items: center;
    padding: 10px 25px;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;

    @media #{$screen-lt-md} {
        padding: 10px;

        .payment-method-cell {
            padding: 0 3px;
            word-break: break-all;
        }
    }

    &:hover {
        @include themify($themes) {
            background-color: themed(mainTableRowBgHover);
        }
    }

    &:first-child {
        margin-top: 30px;
    }

    .logo {
        @include themify($themes) {
            color: themed(selectBtnTxt);
        }
        text-align: left;

        img {
            max-height: 50px;
            max-width: 150px;
        }
    }

    .payment-method-deposit-withdrawal-icon {
        width: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: left center;

        @media #{$screen-lt-sm} {
            width: 60%;
        }
    }

    .adv-cash-logo {
        height: 27px;
        @include colorize(background-image, advCashLogo);
    }

    .sofort-logo {
        height: 27px;
        @include colorize(background-image, sofortLogo);
    }

    .rtp-logo {
        height: 27px;
        @include colorize(background-image, rtpLogo);
    }

    .sepa-logo {
        height: 27px;
        @include colorize(background-image, sepaLogo);
    }

    .paysera-logo {
        height: 27px;
        @include colorize(background-image, payseraLogo);
    }

    .bank-transfer-sepa {
        height: 34px;
        @include colorize(background-image, bankTransferSepaLogo);
    }

    .bank-transfer {
        width: auto;
        height: 45px;
        object-fit: contain;
        margin-left: -10px;

        @media #{$screen-lt-sm} {
            width: 75px;
            margin-left: -8px;
        }
    }
}

.access-button {
    padding: 0 12px !important;
    border-radius: 3px;

}

.count-total-section {
    float: right;
    padding: 0 15px 15px 0;
}

.cash-deposit-close {
    float: right;
    margin-top: 15px !important;
}

.cash-deposit-dialog-header {
    margin-bottom: 16px;
}

.deposit-btn {
    @include themify($themes) {
        background-color: themed(depositBtn) !important;
    }
}

.withdraw-btn {
    @include themify($themes) {
        background-color: themed(withdrawBtn) !important;
    }
}

.bb-coupon {
    @include themify($themes) {
        a {
            color: themed(buyMain) !important;
            &:hover {

            }
        }
    }
}

.airdrop-container {
    margin: 15px 10px;
    .airdrop-item {
        padding: 7px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 8px;
        margin-top: 5px;
        @include themify($themes) {
            border: 1px solid themed(tableBtmBorder);;
        }
        .text {
            font-size: 14px;
            img {
                width: 24px;
                margin-right: 10px;
                margin-left: 5px;
            }
        }
        .claim-button {
            img {
                width: 16px;
                margin-right: 8px;
            }
        }
    }
}

.bold-string {
    font-weight: bold;
}
