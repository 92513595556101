@import '../../../../../node_modules/@angular/material/theming';

@include mat-core();

// You can define colors here (using http://mcg.mbitson.com)
$md-primary: (
    50 : 000000,
    100 : rgb(90, 115, 157),
    200 : #BD998C,
    300 : #AC7561,
    400 : #AD6345,
    500 : #ffffff,
    600 : #B65B37,
    700 : #AD4014,
    800 : #B83907,
    900 : #B13403,
    A100 : #c6cbff,
    A200 : #939dff,
    A400 : #606eff,
    A700 : #4757ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-accent: (
    50 : #AF9538,
    100 : #ffecb5,
    200 : #ffe083,
    300 : #ffd451,
    400 : #ffca2c,
    500 : rgb(255, 198, 1),
    600 : #ffbb06,
    700 : #ffb305,
    800 : #ffab04,
    900 : #ff9e02,
    A100 : #ffffff,
    A200 : #fffaf2,
    A400 : #ffe4bf,
    A700 : #ffd9a6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// mandatory stuff for theming
$egret-primary: mat-palette($md-primary);
$egret-accent: mat-palette($md-accent);

// include the custom theme components into a theme object
$egret-theme: mat-light-theme($egret-primary, $egret-accent);

$primary-dark: darken(map-get($md-primary, 500), 8%);

// include the custom theme object into the angular material theme
.egret-indigo {
    @include angular-material-theme($egret-theme);

    .mat-toolbar {
        position: fixed;
        width: 100%;
        z-index: 100;
        background: #21324e;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    }

    .mat-drawer-container {
        background-color: rgb(240, 245, 251);
        color: rgb(40, 60, 90);
    }

    .mat-dialog-container {
        &.small-dialog {
            max-width: 364px;
        }
    }

    .menu-item:hover {
        color: rgb(43, 165, 200);
    }

    .mat-toolbar {
        padding: 0;
    }

    .btn-gray {
        background-color: #6f7a83;
        &:hover {
            background-color: #586067;
        }
    }

    .text-color {
        .mat-select-value {
            color: white;
        }
    }
    .mat-select-value {
        color: rgba(0, 0, 0, 0.54);
    }
    .mat-option,
    .mat-option.mat-active {
        color: black
    }
    .arrow-black{
        .mat-select-arrow {
            color: black
        }
    }
    .mat-select-arrow {
        color: white
    }
    .mnemonic-field {
        resize: none;
    }

    .mat-bg-primary {
        background: rgb(42, 58, 91) !important;
        color: #FFFFFF !important;
    }

    .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
    .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
    .fileupload-drop-zone.dz-file-over,
    .toolbar-avatar.online > .status-dot,
    .cal-open-day-events {
        background: #36987e;
        color: #FFFFFF !important;
    }
    .topbar {
        position: fixed;
        z-index: 10;
        color: rgb(90, 115, 157) !important;
    }

    .mat-color-primary,
    .list-item-active,
    .sidebar-panel.mat-sidenav .sidebar-list-item.open mat-icon:not(.menu-caret) {
        color: map-get($md-primary, 500) !important
    }
    .sidebar-panel.mat-sidenav .sidebar-list-item.open mat-icon:not(.menu-caret),
    .list-item-active {
        border-color: map-get($md-primary, 500) !important;
    }
    .default-bg {
        background: rgb(33, 50, 78);
        color: rgb(90, 115, 157);
    }


    .mat-bg-accent {
        background-color: map-get($md-primary, 500);
        color: #ffffff;
    }
    .mat-bg-warn {
        background-color: #f44336;
        color: white;
    }
    .mat-color-accent {
        color: map-get($md-accent, 500);
    }
    .mat-color-warn {
        color: #f44336;
    }

    .mat-select-panel mat-option.mat-option,
    .mat-option {
        opacity: 1;
    }
    .mat-autocomplete-panel {
        background-color: map-get($colors, main-bg-color-active) !important;
    }
}

.mat-toolbar-layout {
    position: fixed;
    width: 100%;
    z-index: 100;
    background: #21324e;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.incom-outgo-item {
    &.first-item {
        padding-left: 5px;
    }
    max-height: 40px;
    .content {
        font-size: 20px;
    }
    p {
        margin: 0;
        word-wrap: break-word;
        font-weight: bold;
    }
}

.incom-outgo-details-item {
    padding: 0 0 15px 0;
    color: rgb(40, 60, 90);

    .details-container {
        width: 100%;

        .details-wrapper {
            padding: 15px 0 0 5px;

            .details-item {
                .incom-outgo-details-content {
                    box-sizing: border-box;
                    padding: 2px 5% 0 0;
                }
            }
        }
    }
}

.mat-tab-header-pagination {
    display: none !important;
}

.configurations {
    .mat-tab-body-content {
        min-height: 265px;
        .first-card {
            padding: 2.5rem 2.5rem 2.5rem !important;
            margin-bottom: 0;
        }
    }
}

.incom-outgo-total {
    height: 50px;
    line-height: 50px;
    padding: 0 1.2rem;
    border-top: 1px solid rgba(0, 0, 0, .12);
}

.info-outline-icon {
    margin: 9px 0 0 0;
    font-size: 26px;
}

.import-export-icon {
    margin-top: 5px;
    font-size: 26px;
}

․call-mat-icon {
    font-size: 22px;
    margin-top: 2px;
}

.expand-more-less-icon {
    font-size: 26px;
    color: rgb(130, 130, 130);
}

.mat-button,
.mat-raised-button {
    font-weight: 400 !important;
}


@media screen and (min-width: 1500px) {
    .mat-toolbar {
        padding-right: 272px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1499px) {
    .mat-toolbar {
        padding-right: 272px !important;
    }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
    .mat-toolbar {
        padding-right: 272px !important;
    }
    .mat-button,.mat-raised-button {
        padding: 0 7px !important;
    }
}

@media screen and (min-width: 600px) and (max-width: 959px) {
    .mat-toolbar {
        padding-right: 32px !important;
    }
    .mat-button,.mat-raised-button {
        padding: 0 7px 0 7px !important;
        min-width: 93px !important;
    }
    .balance-content {
        font-size: 17px !important;
    }
}

@media screen and (max-width: 599px) {
    .orders-header-text {
        margin-bottom: 20px;
        letter-spacing: .2em;
        font-size: 1.2rem;
    }
    .mat-toolbar {
        padding-right: 32px !important;
    }
    .incom-outgo-item {
        .content, strong {
            font-size: 10px;
        }
        p {
            font-size: 10px;
        }
    }
    th {
        &.incom-outgo-item {
            .content {
                font-size: 12px;
            }
        }
    }
    .mat-icon {
        font-size: 19px;
        &.expand-more-less-icon {
            padding: 6px 0 0 0;
            margin: 0;
            width: 22px;
        }
    }
    .info-outline-icon {
        padding: 6px 0 0 0;
        margin: 0;
    }
    .call-mat-icon {
        padding: 6px 0 0 5px;
    }
    .incom-outgo-details-content {
        p, strong {
            font-size: 11px;
        }
    }
    .mat-paginator {
        .mat-icon-button {
            width: 25px;
            height: 25px;
        }
    }
    .mat-paginator-page-size {
        max-width: 50%;
    }
    .mat-paginator-page-size-label {
        font-size: 12px;
        white-space: nowrap;
    }
    .mat-paginator-range-label {
        margin: 0 10px !important;
    }
    .mat-tab-labels {
        flex: 1 1 100%;
        max-width: 100%;
        justify-content: center;
    }
    .mat-tab-label {
        min-width: 33% !important;
        white-space: normal !important;
        font-size: .9em;
    }
    .mat-checkbox-label {
        font-size: .9em;
    }
    .configs-small-button {
        padding: 0 .9em !important;
        font-size: .9em;
    }
    .table-data-small-item {
        font-size: .55rem !important;
        font-weight: bold;
    }
}
