.navbar-nav {
    @include themify($themes) {
        .dropdown-toggle i,
        .dropdown-menu li a {
            color: themed(mainTxt);
        }
    }
}

.session-container,
.session-form {
    @include themify($themes) {
        background-color: themed(mainContainerBg);
        .head-logo {
            .head-logo-image {
                content: themed(sessionLogo);
            }
        }
        .create-account,
        .password-forgot {
            color: themed(themeIconActive);

            a {
                color: themed(themeIconActive);
            }
        }
        .policy {
            color: themed(mainTxt) !important;

            a, a:hover {
                color: themed(mainTxt) !important;
            }
        }
    }
}

.form-header {
    @include themify($themes) {
        background-color: themed(mainContainerBg);
    }
}

input:-webkit-autofill {
    @include themify($themes) {
        background-color: themed(mainContainerBg) !important;
        -webkit-box-shadow: inset 0 0 0 9999px themed(mainContainerBg) !important;
        -webkit-text-fill-color: themed(mainTxt) !important;
    }
}

.session-button {
    @include themify($themes) {
        background-color: themed(accessBtnBg);
        color: themed(accessBtn);
    }
}

header,
.user_header {
    @include themify($themes) {
        background-color: themed(headerBg);
        #dropdown-header-menu {
            background-color: themed(dropdownItemBg);
        }
    }
}

.pair-balances-widget {
    @include themify($themes) {

        .balances {
            &:hover {
                //background-color: rgba(0, 0, 0, 0.1);
            }

            .base-balance {
                border-color: themed(borderBtm);
            }

            .quote-balance,
            .base-balance {
                color: themed(chartTitle) !important;
            }
        }
    }
}

.main-app-container {
    @include themify($themes) {
        border-color: themed(mainBorder);
        background-color: themed(mainContainerBg);
        .order-type {
            .order-type-button {
                border-bottom-color: transparent;
                color: themed(mainTxt);

                &.active.sell,
                &:hover.limit,
                &:hover.market,
                &:hover.margin,
                &.active.limit,
                &.active.market,
                &.active.margin {
                    border-bottom-color: themed(selectBtnHover);
                }
            }
        }
        .order-info .total {
            color: themed(mainTxt);
        }
        .info-text {
            color: themed(mainTxt) !important;
        }
        .estimated-amount {
            color: themed(mainTxt);
        }
        .chart-title,
        .container-title {
            color: themed(chartTitle) !important;
            border-color: themed(borderBtm) !important;
        }
        .trading-history {
            .list {
                color: themed(mainTxt) !important;

                .cancel-order {
                    background-color: themed(orderCancel) !important;
                    color: themed(orderCnlTxt) !important;
                }
            }
        }
    }
}

.widget-container {
    @include themify($themes) {
        border-color: themed(mainBorder);
        // background-color: themed(widgetBg);
    }
}

.widget-frame {
    @include themify($themes) {
        border-color: themed(mainBorder);
    }
}

.no-data-box {
    @include themify($themes) {
        border-color: themed(mainBorder);
    }
}

.show-all-button {
    @include themify($themes) {
        color: themed(showBtn);
        border-color: themed(showBtnBorder);
    }

    &:hover {
        @include themify($themes) {
        }
    }
}

.main-page-table {
    @include themify($themes) {
        .main-page-table-header {
            color: themed(mainTxt);
            border-bottom-color: themed(borderBtm);
        }
        .main-page-table-body {
            color: themed(mainTxt);

            .table-row {
                border-bottom-color: themed(borderBtm);

                &:hover {
                    background-color: themed(mainTableRowBgHover);
                }

                @media #{$screen-lt-md} {
                    .table-col {
                        border-bottom-color: themed(borderBtm);
                    }
                }

                .sell {
                    color: themed(sellMain);
                }

                .buy {
                    color: themed(buyMain);
                }
            }
        }
    }
}

b {
    @include themify($themes) {
        &.positive-change {
            color: themed(buyMain);
        }
        &.negative-change {
            color: themed(sellMain);
        }
    }
}

.symbol-info {
    @include themify($themes) {
        color: themed(chartTitle);
        .value {
            color: themed(chartTitleValue);
        }
    }
}

body,
.wrapper {
    @include themify($themes) {
        background-color: themed(mainBg) !important;
    }
}

.header-container,
.footer-container {
    @include themify($themes) {
        .logo {
            background-image: themed(headerLogo);
        }
        .head-menu,
        .footer-menu {
            .head-menu-item,
            .footer-menu-item {
                &:hover a,
                &:hover a i {
                    color: themed(headerItemActive) !important;
                }

                a {
                    color: themed(headerTxt) !important;

                    &.active,
                    &.active i {
                        color: themed(headerItemActive) !important;
                    }

                    &.active {
                        font-weight: bold;
                    }

                    i {
                        color: themed(headerIcon) !important;
                    }
                }
            }
        }
    }
}

.header-container {
    @include themify($themes) {
        .user-panel {
            .dropdown-menu li {
                background-color: themed(dropdownItemBg);

                &:hover {
                    background-color: themed(dropdownItemBgHover);
                }

                span.language,
                span {
                    color: themed(headerTxt);
                }
            }

            #language_bar {
                #lanNavSel,
                .caret {
                    color: themed(headerTxt);
                }
            }

            &:hover {
                .img-thumbnail {
                    background-color: themed(headerTxt);
                    border-color: themed(headerTxt);
                }
            }

            #name,
            .caret {
                color: themed(headerTxt);
            }

            .img-thumbnail {
                background-color: themed(headerTxt);
                border-color: themed(headerTxt);
            }

            .profile-icon {
                background-image: themed(userIconImg);
            }
        }
        .theme-bar {
            .theme-icon {
                &.active {
                    .material-icons {
                        color: themed(themeIconActive);
                    }
                }

                .material-icons {
                    color: themed(themeIcon);
                }
            }
        }
    }
}

#dropdown-header-menu {
    @include themify($themes) {
        background-color: themed(dropdownItemBg) !important;
    }

    .head-menu-item {
        @include themify($themes) {
            background-color: themed(dropdownItemBg) !important;
        }

        &:hover {
            @include themify($themes) {
                background-color: themed(dropdownItemBgHover) !important;
            }
        }

        a {
            @include themify($themes) {
                color: themed(headerTxt) !important;
            }
        }

        i {
            @include themify($themes) {
                color: themed(headerIcon) !important;
            }
        }
    }
}

.main-app-container {
    @include themify($themes) {

        .sell-buy-bids {
            span {
                &.buy {
                    background-color: themed(buyMain);
                }

                &.sell {
                    background-color: themed(sellMain);
                }
            }
        }

        .buy-orders-title,
        .sell-orders-title {
            color: themed(mainTxt);
        }
        .sell-orders-table,
        .buy-orders-table {
            .table-head {
                color: themed(mainTxt);
                border-bottom-color: themed(tableBtmBorder);
            }

            .bid-tr {
                border-bottom-color: themed(tableBtmBorder);

                th, div {
                    color: themed(mainTxt);
                }
            }

            .bid-tr-layer {
                &.buy-layer {
                    background-color: themed(buyLayer);
                }

                &.sell-layer {
                    background-color: themed(sellLayer);
                }
            }
        }
        .sell-orders-table {
            .bid-tr td,
            .bid-tr div {
                &:first-of-type {
                    color: themed(sellMain) !important;
                }
            }
        }
        .buy-orders-table {
            .bid-tr td,
            .bid-tr div {
                &:last-of-type {
                    color: themed(buyMain) !important;
                }
            }
        }
        .market-history {
            span {
                &.buy {
                    background-color: themed(buyMain) !important;
                    border-color: themed(buyMain);
                }

                &.sell {
                    background-color: themed(sellMain) !important;
                    border-color: themed(sellMain);
                }
            }

            .table-head {
                color: themed(mainTxt);
                border-bottom: 1px solid themed(borderBtm);
            }

            .bid-tr {
                color: themed(borderBtm);

                &.sell {
                    color: themed(sellMain);

                    &.first {
                        background-color: themed(sellMainFirst);
                    }

                    &.second {
                        background-color: themed(sellMainSecond);
                    }

                    &.third {
                        background-color: themed(sellMainThird);
                    }
                }

                &.buy {
                    color: themed(buyMain);

                    &.first {
                        background-color: themed(buyMainFirst);
                    }

                    &.second {
                        background-color: themed(buyMainSecond);
                    }

                    &.third {
                        background-color: themed(buyMainThird);
                    }
                }

                td {
                    color: themed(mainTxt);
                }
            }
        }
    }
}

.container-header {
    @include themify($themes) {
        border-color: themed(tableBtmBorder);
    }
}

.main-page-table {
    .table-row {
        font-family: sans-serif;
        @include themify($themes) {
            background-color: themed(mainTableRowBg);
            border-bottom: 1px solid themed(borderBtm);
            color: themed(mainTxt);
        }

        &:hover {
            @include themify($themes) {
                background-color: themed(mainTableRowBgHover);
            }
        }

        .sell {
            @include themify($themes) {
                color: themed(sellMain);
            }
        }

        .buy {
            @include themify($themes) {
                color: themed(buyMain);
            }
        }
    }
}

.button-buy {
    @include themify($themes) {
        background-color: themed(buyMain);
        color: themed(sellBuyBtnTxt);
    }
}

.button-sell {
    @include themify($themes) {
        background-color: themed(sellMain);
        color: themed(sellBuyBtnTxt);
    }
}

.button-primary {
    @include themify($themes) {
        background-color: themed(buyMain) !important;
        color: themed(sellBuyBtnTxt) !important;
    }
}

.button-red {
    @include themify($themes) {
        background-color: themed(sellMain) !important;
        color: themed(sellBuyBtnTxt) !important;
    }
}

.bid-tr {
    font-family: sans-serif;
    @include themify($themes) {
        /*&:nth-of-type(odd) {
            background-color: themed(mainTableRowBg);
        }*/
        border-bottom: 1px solid themed(tableBtmBorder) !important;
        color: themed(mainTxt) !important;
    }

    &:hover {
        @include themify($themes) {
            background-color: themed(mainTableRowBgHover) !important;
        }
    }

    td {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }
}

.pair-info-row {
    @include themify($themes) {
        background-color: themed(headerBg);
    }
}

.new-pair-selector {
    @include themify($themes) {
        color: themed(chartTitle) !important;
    }
}

.new-pair-selector-container {
    @include themify($themes) {
        .pairs-list-container {
            color: themed(mainTxt);

            .select {
                color: themed(mainTxt) !important;
                background-color: themed(selectBg) !important;
                outline: none;
            }
        }
    }

    .sort-arrow {
        path {
            @include themify($themes) {
                fill: themed(headerTxt) !important;
            }
        }

        &.min-max, &.a-z {
            path:last-child {
                @include themify($themes) {
                    fill: themed(sortArr) !important;
                }
            }
        }

        &.max-min, &.z-a {
            path:first-child {
                @include themify($themes) {
                    fill: themed(sortArr) !important;
                }
            }
        }
    }
}

.main-table-head {
    @include themify($themes) {
        border-bottom-color: themed(borderBtm) !important;
    }

    th {
        @include themify($themes) {
            color: themed(mainTxt) !important;
            &.sortable {
                &:hover {
                    background-color: themed(dropdownItemBgHover) !important;
                }
            }
        }
    }
}

.main-table-row {
    @include themify($themes) {
        color: themed(tableRowTxt) !important;
        border-bottom-color: themed(tableBtmBorder) !important;

        &:hover {
            background-color: themed(mainTableRowBgHover) !important;
        }
    }
}

.fees-container {
    @include themify($themes) {
        .group-info-row-header,
        .group-info-row {
            border-bottom-color: themed(borderBtm);
            color: themed(mainTxt);
        }
        .group-info-row {
            .fee {
                &.changed,
                .changed {
                    color: themed(sellMain);
                }
            }

            .action {
                color: themed(sellMain);
            }

            input {
                border-color: themed(mainInputBorder);
                color: themed(mainTxt);
            }

            &:nth-child(odd) {
                background-color: themed(mainTableRowBgHover);
            }
        }
    }
}

.new-setting-box {
    @include themify($themes) {
        &:hover {
            .material-icons {
                &.delete-icon {
                    color: themed(sellMain);
                }

                &.add-icon {
                    color: themed(buyMain);
                }
            }
        }
    }
}

.mat-form-field {
    @include themify($themes) {
        .mat-form-field-wrapper,
        .mat-form-field-appearance-legacy .mat-form-field-wrapper {
            border-color: themed(mainInputBorder);
        }
        .mat-form-field-ripple {
            background-color: themed(matRipple) !important;
        }
        &.mat-focused {
            .mat-form-field-ripple {
                background-color: themed(matRippleActive) !important;
            }
        }
        .input-symbol {
            .material-icons {
                color: themed(mainTxt) !important;
            }
        }
        .input-symbol {
            color: themed(mainTxt) !important;
        }
    }
}

.mat-input-element {
    @include themify($themes) {
        color: themed(mainTxt) !important;
        caret-color: themed(mainTxt) !important;
    }
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    @include themify($themes) {
        background-color: themed(borderBtm) !important;
    }
}

.advanced-options,
.mat-select-value-text,
.mat-select-arrow,
.estimated-amount {
    @include themify($themes) {
        color: themed(mainTxt) !important;
    }
}

.mat-input-element:disabled {
    @include themify($themes) {
        color: themed(mainTxt) !important;
    }
}

.mat-datepicker-content {
    @include themify($themes) {
        background-color: themed(widgetBg);
        color: themed(mainTxt);
    }
}

.mat-calendar-table-header,
.mat-calendar-body-label,
.mat-calendar-body-cell-content,
.mat-calendar-body-selected,
.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button,
.mat-calendar-table-header,
.mat-select-search-no-entries-found,
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
    @include themify($themes) {
        color: themed(mainTxt) !important;
    }
}

.mat-calendar-body-selected {
    @include themify($themes) {
        color: themed(mainTxt);
        background-color: themed(selectedCalendar);
    }
}

.select-search-container {
    @include themify($themes) {
        .mat-datepicker-content {
            background-color: themed(selectSearchBg) !important;
        }
        .mat-select-search-inner .mat-input-element {
            color: themed(mainTxt) !important;
            caret-color: themed(mainTxt) !important;

            &::placeholder {
                color: themed(mainTxt) !important;
            }
        }
    }
}

.mat-calendar-period-button {
    @include themify($themes) {
        color: themed(mainTxt);
    }
}

.mat-select-search-no-entries-found {
    @include themify($themes) {
        background-color: themed(selectSearchBg);
    }
}

.pagination {
    @include themify($themes) {
        .current-page {
            color: themed(mainTxt);
        }
        mat-paginator {
            // background-color: themed(mainContainerBg);
            background-color: transparent;
            color: themed(mainTxt);
        }
        .mat-paginator-icon {
            fill: themed(mainTxt) !important;
        }
    }
}

.buy-checkbox,
.sell-checkbox,
.gender-checkbox {
    .mat-radio-container {
        @include themify($themes) {
            .mat-radio-outer-circle {
                border-color: themed(radioBtn) !important;
            }
            .mat-radio-inner-circle {
                background-color: themed(radioBtn) !important;
            }
            .mat-radio-outer-circle {
                border-color: themed(radioBtn) !important;
            }
        }
    }
}

.mat-snack-bar-container {
    @include themify($themes) {
        background-color: themed(snackbarBg) !important;
        border: 1px solid themed(snackbarBorder) !important;
        color: themed(mainTxt);
    }
}

.error-msg {
    @include themify($themes) {
        color: themed(error);
    }
}

.no-info,
.open-orders .no-info {
    @include themify($themes) {
        color: themed(mainTxt);
        border-color: themed(mainBorder);
    }
}

.open-orders {
    @include themify($themes) {
        .bid-tr {
            .sell {
                color: themed(sellMain);
            }

            .buy {
                color: themed(buyMain);
            }

            border-color: themed(tableBtmBorder);

            &:not(.buy):hover,
            &:not(.sell):hover {
                background-color: themed(mainTableRowBgHover);
            }
        }
    }
}

.trading-history {
    @include themify($themes) {
        .list {
            .buy, .buy-date,
            .sell-date.long-date {
                color: themed(buyMain);
            }

            .sell, .sell-date,
            .sell-date.short-date {
                color: themed(sellMain);
            }
        }
        .close-position {
            &.long, &.short {
                color: themed(sellBuyBtnTxt);
            }

            &.long {
                background-color: themed(buyMain);
            }

            &.short {
                background-color: themed(sellMain);
            }
        }
    }
}

.export-balance-csv {
    @include themify($themes) {
        border-color: themed(exportBtnBorder) !important;
        color: themed(exportBtnBorder);
    }
}

.wallets,
.dialog-container,
.page-container {
    .content-box,
    .estimated_balance,
    .wallet-action-header,
    &.one-wallet {
        .buttons span {
            @include themify($themes) {
                background-color: themed(mainBtnBg);
                color: themed(mainBtnTxt);
                &:hover {
                }
            }

            &.withdraw {
                @include themify($themes) {
                    background-color: themed(withdrawBtn);
                }
            }
        }

        .info {
            @include themify($themes) {
                color: themed(mainTxt);
            }
        }
    }

    .estimated_balance,
    .wallet-action-header {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }

    .header {
        @include themify($themes) {
            color: themed(mainTxt);
            border-bottom: 1px solid themed(borderBtm);
        }
    }

    .wallet {
        @include themify($themes) {
            background-color: themed(walletRowBg);
            color: themed(mainTxt);
        }
    }
}

.dialog-container {
    .header-box {
        @include themify($themes) {
            color: themed(mainTxt);
            border-bottom-color: themed(borderBtm);
        }
    }
}

.wallet-funds-container {
    .send-for-container {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }

    .notification-for-container {
        @include themify($themes) {
            color: themed(mainTxt);
            .notification {
                border-color: themed(sellMain);
            }
        }
    }
}

.see-details,
.edit-button,
.button-verify {
    @include themify($themes) {
        color: themed(showBtn) !important;
        border-color: themed(showBtnBorder) !important;
    }
}

.edit-button-filled,
.verify-button-filled {
    @include themify($themes) {
        color: themed(accessBtn) !important;
        background-color: themed(showBtnBorder) !important;
    }
}

.access-button {
    @include themify($themes) {
        color: themed(accessBtn);
        background-color: themed(accessBtnBg);
    }
}

.approve-button {
    @include themify($themes) {
        color: themed(buyMain);
        border-color: themed(buyMain);
    }
}

.approve-button-filled {
    @include themify($themes) {
        color: themed(accessBtn) !important;
        background-color: themed(buyMain) !important;
    }
}

.cancel-button,
.reject-button {
    @include themify($themes) {
        color: themed(orderCancel);
        border-color: themed(orderCancel);
    }
}

.cancel-button-filled,
.reject-button-filled {
    @include themify($themes) {
        color: themed(accessBtn) !important;
        background-color: themed(orderCancel) !important;
    }
}

.mat-checkbox-label,
.mat-datepicker-toggle-default-icon {
    @include themify($themes) {
        color: themed(mainTxt);
    }
}

.mat-checkbox-background {
    @include themify($themes) {
        border: 2px solid themed(ceckboxBorder) !important;
    }
}

.mat-checkbox-checkmark-path {
    @include themify($themes) {
        stroke: themed(mainTxt) !important;
    }
}

.mat-pseudo-checkbox-checked {
    @include themify($themes) {
        background: themed(checkboxActive) !important;
    }
}

.mat-icon-button {
    .mat-button-wrapper {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }
}

.header-text,
.content-text {
    @include themify($themes) {
        color: themed(mainTxt);
    }
}

.table {
    .table-header {
        @include themify($themes) {
            color: themed(walletRowTxt);
        }
    }

    .table-body {
        &.withdraw-table-body {
            .table-col {
                justify-content: space-between !important;
            }
        }

        .table-row {
            @include themify($themes) {
                background-color: themed(walletRowBg);
                color: themed(walletRowTxt);
                border-bottom-color: themed(mainBorder);
                &:hover {
                    background-color: themed(mainTableRowBgHover);
                }
                &.column {
                    .table-col {
                        border-bottom: 1px solid themed(borderBtm);
                    }
                }
                @media #{$screen-lt-md} {
                    .table-col {
                        border-bottom: 1px solid themed(borderBtm);
                    }
                }
            }
        }

        .material-icons {
            &:hover {
                color: #cccccc;
            }

            &.deposit {
                @include themify($themes) {
                    color: themed(depositBtn);
                    &:hover {
                        color: themed(depositBtnHover);
                    }
                }
            }

            &.withdraw {
                @include themify($themes) {
                    color: themed(withdrawBtn);
                    &:hover {
                        color: themed(withdrawBtnHover);
                    }
                }
            }
        }

        .wallet-info {
            &.show {
                @include themify($themes) {
                    background-color: themed(walletInfoBg);
                    color: themed(mainTxt);
                }
            }
        }

        .sell, .short {
            @include themify($themes) {
                color: themed(sellMain);
            }
        }

        .buy, .long {
            @include themify($themes) {
                color: themed(buyMain);
            }
        }

        .buy, .long, .sell, .short {
            @include themify($themes) {
                b {
                    color: themed(mainTxt);
                }
            }
        }
    }
}

.cancel-order,
.button-cancel-all {
    @include themify($themes) {
        background-color: themed(orderCancel) !important;
        color: themed(orderCnlTxt) !important;
    }
}

.statistics-info {
    @include themify($themes) {
        color: themed(mainTxt);
    }
}

#profile,
.profile-box {
    h3, .user-verification {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }

    .user-verification {
        @include themify($themes) {
            border-color: themed(borderBtm);
        }
    }

    .del-api-key-btn {
        @include themify($themes) {
            background-color: themed(cancel);
        }
    }

    .user-verification-bordered {
        @include themify($themes) {
            color: themed(mainTxt);
            border-color: themed(noteMsgBorder);
            &.access-bordered {
                border-color: themed(buyMain);
            }
            &.info-bordered {
                border-color: themed(infoBorder);
            }
            &.warning-bordered {
                border-color: themed(sellMain);
            }
        }
    }

    .verify-step-title {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }
}

.button-auth,
.del-api-key-btn,
.get-api-key-btn {
    @include themify($themes) {
        background-color: themed(buyMain);
        color: themed(sellBuyBtnTxt);
    }
}

.button-enable {
    min-width: 200px !important;
    @include themify($themes) {
        background-color: themed(buyMain);
        color: themed(sellBuyBtnTxt);
    }
}

.button-disable {
    min-width: 200px !important;
    @include themify($themes) {
        background-color: themed(sellMain);
        color: themed(sellBuyBtnTxt);
    }
}

.profile-form {
    .dob-field .dob-title,
    .gender-field {
        @include themify($themes) {
            color: themed(mainTxt);
        }
    }
}

footer {
    @include themify($themes) {
        background-color: themed(footerBg);
    }
}

* {
    @include themify($themes) {
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-track:horizontal {
            background-color: themed(scrollbarTrack);
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-thumb:horizontal {
            background-color: themed(scrollbarThumb);
        }

        &::-webkit-scrollbar-thumb:hover,
        &::-webkit-scrollbar-thumb:horizontal:hover {
            background-color: themed(scrollbarThumbHvr);
        }
    }
}

.routing-header-menu-list {
    @include themify($themes) {
        .menu-link {
            .link {
                color: themed(headerTxt);

                &:hover,
                &.active {
                    color: themed(headerItemActive);
                }

                &.active {
                    font-weight: bold;
                }
            }
        }
    }
}

.mat-tab-label-container {
    @include themify($themes) {
        background-color: themed(mainContainerBg);
        .mat-tab-label {
            color: themed(headerTxt);

            &.mat-tab-label-active {
                color: themed(headerItemActive);
            }
        }
    }
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    @include themify($themes) {
        background-color: themed(headerItemActive) !important;
    }
}

.mat-dialog-container {
    @include themify($themes) {
        color: themed(mainTxt);
        background-color: themed(modalBg) !important;
    }
}

.mat-expansion-indicator {
    @include themify($themes) {
        color: themed(mainTxt);
    }
}

.wallet-withdraw-info {
    @include themify($themes) {
        .table-row {
            background-color: themed(walletInfoBg) !important;

            &:hover {
                background-color: themed(mainBg) !important;
            }
        }
    }
}

.mat-form-field {
    @include themify($themes) {
        .mat-form-field-label {
            color: themed(mainTxt) !important;
        }
    }
}

.mat-form-field-appearance-legacy.mat-form-field-can-float {
    @include themify($themes) {
        &.mat-form-field-should-float .mat-form-field-label,
        .mat-input-server:focus + .mat-form-field-wrapper .mat-form-field-label {
            background-color: themed(labelBackground);
        }
    }
}

.mat-dialog-container {
    @include themify($themes) {
        .mat-form-field {
            .mat-form-field-label {
                color: themed(mainTxt) !important;
            }
        }
        .mat-form-field-appearance-legacy.mat-form-field-can-float {
            &.mat-form-field-should-float .mat-form-field-label,
            .mat-input-server:focus + .mat-form-field-wrapper .mat-form-field-label {
                background-color: themed(dialogLabelBg);
            }
        }
    }
}

.login_form_row {
    @include themify($themes) {
        .input-field-icon {
            color: themed(mainTxt);
            background-color: themed(mainContainerBg);
        }
        .eye-icon {
            color: themed(mainTxt) !important;

            &:before {
                border-top-color: themed(mainTxt) !important;
                border-bottom-color: themed(mainContainerBg) !important;
            }
        }
    }
}

.mat-form-field {
    @include themify($themes) {
        &.mat-focused {
            .input-field-icon {
                color: themed(themeIconActive);
            }
        }
    }
}

.mat-calendar-arrow {
    @include themify($themes) {
        border-top-color: themed(mainTxt) !important;
    }
}

.mat-form-field {
    .mat-form-field-type-mat-chip-list {
        @include themify($themes) {
            border-top-color: themed(mainTxt) !important;
        }
    }
}

.line-bottom {
    @include themify($themes) {
        border-bottom: themed(mainBorder);
    }
}

.complete-info {
    @include themify($themes) {
        color: themed(mainTxt) !important;
    }
}

.maintenance-form-box {
    @include themify($themes) {
        .maintenance-row {
            .allowed-ip-label {
                color: themed(mainTxt) !important;
            }
        }
    }
}

.product-uploaded-image {
    @include themify($themes) {
        &.no-image {
            background-image: themed(fileIcon);
        }
    }
}

.help-box {
    @include themify($themes) {
        .help-icon {
            color: themed(headerItemActive);
        }
        .fees-tooltip {
            color: themed(tooltipTxt);
            background-color: themed(tooltipBg);

            .title,
            .title-top {
                color: themed(tooltipTitle);
            }

            .section {
                span {
                    color: themed(tooltipTitle);
                }
            }
        }
    }
}

.kyc-reject-reason{
    width: 100%;
}
