@import "~@ng-select/ng-select/themes/default.theme.css";
/* Theme main colors */
@import 'theme-colors';
/* Theme mixin */
@import 'theming';
/* Flex layouts */
@import 'flexology';
/* Egret stylesheets */
@import './media-sizes';
/* Mixins */
@import './mixins';
/* Common stylesheets */
@import './variables';
/* Common stylesheets */
@import './common';
/* Style matching on different browsers */
@import './all-browsers';
/* App stylesheets */
@import './app';
/* App profile stylesheets */
@import './profile';
/* App main page stylesheets */
@import './main_page';
/* Sessions stylesheets */
@import './sessions';
/* Wallets stylesheets */
@import './wallets';
/* Egret stylesheets */
@import './egret-indigo';
/* Material rewrite stylesheets */
@import './material-rewrite';
/* Dialogs stylesheets */
@import './dialogs';
/* App admin page stylesheets */
@import './admin_page';
/* App responsive stylesheets */
@import './responsive';
/* Tables stylesheets */
@import './tables';
/* Uploader stylesheets */
@import './uploader';
/* Maintenance stylesheets */
@import './maintenance';
/* Selector */
@import './selector';
/* Margin trading */
@import './margin-trading';
/* Meta trader */
@import './meta-trader';
/* Theming stylesheets */
@import './theme-styles';
/* Font mixin */
@import './font';
/* Notification mixin */
@import './notification';

