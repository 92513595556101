/* Replacer function */

@function str-replace($string, $search, $replace: "") {
    $index: str-index($string, $search);

    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }

    @return $string;
}

/* Font face mixin */

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
    $src: null;

    $extmods: (
        eot: "?",
        svg: "#" + str-replace($name, " ", "_")
    );

    $formats: (
        otf: "opentype",
        ttf: "truetype"
    );

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map_get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map_get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
    }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src;
    }
}

@include font-face('Poppins Light', '/assets/styles/fonts/poppins/Poppins-Light', 300, normal, ttf);
@include font-face('Poppins Medium', '/assets/styles/fonts/poppins/Poppins-Medium', 400, normal, ttf);
@include font-face('Poppins Regular', '/assets/styles/fonts/poppins/Poppins-Regular', 500, normal, ttf);
@include font-face('Poppins Bold', '/assets/styles/fonts/poppins/Poppins-Bold', 700, normal, ttf);
