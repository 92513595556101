.position-chart-canvas {
    height: 400px;
    @media #{$screen-lt-md} {
        height: 200px;
    }
}

.margin-trading-user-tabs {
    padding: 0 15px 15px 15px;
}

.position-title-info{
    font-size: 11px;
    line-height: 27px;
    margin-left: 15px;
    color: #8da5d7;
}

@media screen and (max-width: 599px) {
    .position-title-info{
        line-height: 20px;
        font-size: 9px;
    }
}
