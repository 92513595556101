.file-icon-close{
    background-image: url("../../img/file-icon.svg");
}

.uploader-plugin {
    mat-progress-bar{
        height: 100%;
    }
}
.upload-image-input-box {
    input,
    .upload-image-input {
        display: none;
    }
}
.cdk-overlay-pane{
    height: auto !important;
}

.mat-progress-bar .mat-progress-bar-element, .mat-progress-bar .mat-progress-bar-fill::after {
    transition: width 3s ease;
}

app-upload-dialog {
    .table-row{
        margin: 5px 10px !important;
        padding: 5px 0 !important;
    }
    mat-progress-bar{
        .mat-progress-bar-background {

        }
    }
}


.uploaded-images-list-container{
    margin-bottom: 20px;
}

.egret-indigo .mat-progress-bar-fill::after{
    //background-color: $body-bg;
}

app-kyc{
    app-upload {
        border-bottom: 1px solid $main-bg-color-active;
        padding-bottom: 20px;
    }
}

.uploader-progress {
    position: relative;
    b{
        position: absolute;
        text-align: center;
        left: 0;
        font-size: 9px;
        z-index: 100;
        width: 100%;
        line-height: 16px;
    }
}

//New CSS start


.uploaded-images-list-container .uploaded-image {
    object-fit: contain;
}

.uploader-progress-percent{
    strong{
        white-space: nowrap;
        margin-right: 5px;
    }
}

.mat-progress-bar .mat-progress-bar-fill::after{
    height: 4px !important;
    background: #fec25e;
}

.egret-indigo .mat-progress-bar-buffer {
    background-color: white;
}

.margin-md-bottom{
    margin-bottom: 10px;
}

.uploaded-images-container {
    @include themify($themes) {
             border: 1px solid themed(mainInputBorder);
         }
    //background-color: white;
    //border: 1px solid #67757c;
    border-radius: 8px;
}
.uploaded-images-list-container .uploaded-image{
    @include themify($themes) {
        border: 1px solid themed(mainInputBorder);
    }
}

.ellipsis{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 10px;
}

.hide-mobile{
    @media #{$screen-lt-sm} {
        display: none !important;
    }
}

.upload-dialog-container{
    width: 1000px !important;
    max-width: 95% !important;
    margin-left:auto;
    margin-right: auto;
}

.dialog-container .content-box{
    max-height: 100%;
}
//New CSS end

//CSS for app-cash-withdraw start
app-cash-withdraw{
    display: block;
    //padding: 20px;

    .table .table-body .table-col{
        justify-content: space-between;
    }

    .table .table-body .table-row:hover{
        background-color: inherit !important;
    }
}
//CSS for app-cash-withdraw end

