.main-exchange {
    padding-top: 10px;
    padding-bottom: 10px;

    .container-fluid {
        padding-right: 10px;
        padding-left: 10px;
    }

    .row {
        margin-right: -5px;
        margin-left: -5px;
    }

    .main {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.symbol-info-widget {
    .chart-title {
        flex: 1 1 100%;
        max-width: 100%;
        padding: 0 5px;
        @media only screen and (max-width: 991px) {
            display: none !important;
        }
    }
}

.pair-info-row {
    position: sticky;
    top: 0;
    width: 100%;
    height: 64px;
    padding: 0 15px;
    z-index: 10;
    box-shadow: 0 0 5px 0 rgba(142, 139, 133, 0.32);
    @media only screen and (max-width: 959px) {
        padding: 0 4px;
    }
}

.pair-info-box-inner {
    height: 100%;
}

.pair-search-field {
    .mat-input-element {
        display: flex;
        flex: 1 1 90%;
        max-width: 90%;
    }

    .input-symbol {
        position: static !important;
        display: flex;
        flex: 1 1 10%;
        max-width: 10%;

        .material-icons {
            top: 0;
            right: 8px;
            font-size: 15px;
        }
    }
}

.pair-selection-wrapper,
.pair-balances-widget {
    padding: 0 15px;
}

.balances {
    min-width: 180px;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 1.1em;
    line-height: 15px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
    @media only screen and (max-width: 1200px) {
        font-size: .9em;
    }

    .quote-balance,
    .base-balance {
        width: 100%;
        padding: 5px;
        transition: opacity .2s ease-in-out;

        img {
            height: 18px;
            float: left;
            margin-right: 10px;
        }

        b {
            margin-left: 10px;
        }
    }

    .quote-balance:hover,
    .base-balance:hover {
        opacity: 0.7;
    }
}

.main-app-container {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid;
    box-shadow: 0 0 5px 0 rgba(142, 139, 133, 0.32);
    transition: background-color .3s ease;

    .box-shadow-none {
        box-shadow: none;
    }

    .selected-symbol {
        position: relative;
        padding: 10px;
        margin: 4px;
        -webkit-box-shadow: 0px 0px 15px 0px #252535;
        -moz-box-shadow: 0px 0px 15px 0px #252535;
        box-shadow: 0px 0px 15px 0px #252535;
        color: #ffffff;
        border-radius: 3px;
        background-color: #314456;
        cursor: pointer;

        .list-popup-arrow {
            float: right;
        }

        i {
            float: left;
            line-height: 20px;
            margin-right: 5px;
        }

        b {
            float: right;

            span {
                color: $basic-green;
            }
        }
    }

    .container-header {
        .container-title {
            border: unset;
            margin-bottom: unset;
            padding-bottom: unset;
        }
    }

    .container-title {
        margin: 0 -15px 10px;
        padding: 0 15px 15px;
        line-height: 22px;
        font-size: 18px;
        border-bottom-width: 1px;
        border-bottom-style: solid;
    }

    .order-info {
        padding: 10px;

        .mat-expansion-panel {
            width: 100%;
        }

        mat-form-field {
            margin-bottom: 10px;

            .mat-input-element {
                display: flex;
                flex: 1 1 80%;
                max-width: 80%;
            }

            .input-symbol {
                display: flex;
                flex: 1 1 18%;
                max-width: 18%;
                margin: 2%;
                justify-content: flex-end;
            }
        }

        .leverage {
            display: block;
            width: 100%;
            color: #616161;
        }

        .short-long-price {
            margin-top: 15px;
            font-size: 12px;

            .input-symbol {
                top: 7px;
            }

            .estimated-amounts {
                font-size: 10px;
            }
        }

        .long-position {
            color: #ffffff;
        }

        .short-position {
            color: #ffffff;
        }

        .estimated-amounts {
            margin-bottom: 15px;
            font-size: 12px;

            > div:first-child {
                margin-bottom: 5px;
            }
        }

        .short-position,
        .long-position {
            .mat-form-field {
                @media screen and (min-width: 768px) and (max-width: 1324px) {
                    .mat-input-element {
                        width: 64% !important;
                        max-width: 64% !important;
                        font-size: 1rem;

                    }
                }
                @media screen and (max-width: 767px) {
                    .mat-input-element {
                        width: 76% !important;
                        max-width: 76% !important;
                        font-size: 1rem;
                    }
                }
                @media #{$screen-lte-1324px} {
                    .mat-form-field-label {
                        top: 2.2em;
                        font-size: .8rem;
                    }
                    .input-symbol {
                        font-size: .8rem;
                    }
                }
            }
        }
    }

    .submit-order {
        padding: 10px 0;
    }

    .chart-expand {
        float: right;
        font-size: 18px;
        margin-top: 9px;
        margin-right: -5px;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }

        @media #{$screen-lte-992px} {
            display: none;
        }
    }

    .order-type-container {
        padding: unset;
    }

    .order-type {
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 100%;
        max-width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;

        .limit {
        }

        .market, .margin {
        }

        .order-type-button {
            display: flex;
            padding: 10px 0;
            border-bottom-width: 4px;
            border-bottom-style: solid;
            justify-content: center;
            line-height: 22px;
            font-size: 18px;
            cursor: pointer;
            transition: border-bottom-color .2s ease;

            &.one-second-part {
                flex: 1 1 50%;
                max-width: 50%;
            }

            &.one-third-part {
                flex: 1 1 33.3%;
                max-width: 33.3%;
            }
        }
    }

    .input-symbol {
        /*position: absolute;
        right: 10px;*/
        text-align: right;
    }

    .market-total {
        color: #ffffff;
    }

    .chart-title {
        position: absolute;
        right: 15px;
        line-height: 22px;
        font-size: 18px;
        @media #{$screen-lt-md} {
            font-size: 10px
        }
    }

    .buy-orders-title,
    .sell-orders-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 3px;
        font-size: 18px !important;
        transition: opacity .3s ease;

        b {
            float: right;
            font-size: 13px;
            line-height: 23px;
        }
    }

    .buy-orders-table,
    .sell-orders-table {
        height: 200px;
        margin-right: -5px;
        padding-right: 2px;
        overflow-y: auto;

        .table-header {
            width: 100%;
            margin-top: 5px;
        }

        .table-head {
            border-bottom: 1px solid;

            th {
                width: 40%;
                padding: 3px 4px;
                font-size: 12px;

                &:first-child {
                    // padding-left: 15px;
                }

                &:last-child {
                    // padding-right: 20px;
                    text-align: right;
                }
            }
        }

        .bid-tr {
            position: relative;
            border-bottom: 1px solid;
            line-height: 24px;
            max-width: 100%;

            &:hover {
                .sell-layer, .buy-layer {
                    filter: brightness(1.1);
                }
            }

            td, div {
                padding: 0 3px;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 13px;
                font-weight: 200;
                cursor: pointer;
            }

            div {
                &:first-of-type {
                }

                &:last-of-type {
                    justify-content: flex-end;
                }

                &:last-child {
                    justify-content: flex-end;
                }
            }

            .bid-tr-layer {
                position: absolute;
                display: inline-block;
                height: 100%;
                top: 0;

                &.buy-layer {
                    right: 0;
                }

                &.sell-layer {
                    left: 0;
                }
            }
        }
    }

    .open-orders {
        .table-head {
            color: #ffffff;
            border-bottom: 1px solid $main-bg-color-active;

            th {
                padding: 3px 6px;
                font-size: 11px;

                &:last-child {
                    text-align: right;
                }
            }
        }

        .bid-tr {
            border-bottom: 1px solid;

            td {
                vertical-align: top;
                font-size: 11px;
                padding: 3px 6px;
                cursor: pointer;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

    .my-trade-history {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
    }

    .market-history {
        margin: 0 -10px;

        &.with-open-orders-table {
            height: 755px;
        }

        span {
            &.sell, &.buy {
                display: inline-block;
                width: 8px;
                height: 8px;
                position: absolute;
                top: 15px;
                left: 5px;
                border-radius: 9px;
                border-width: 1px;
                border-style: solid;
            }
        }

        .table-head {
            color: #ffffff;
            border-bottom: 1px solid $main-bg-color-active;

            th {
                padding: 3px 6px;
                font-size: 11px;

                &:last-child {
                    text-align: right;
                }
            }
        }

        .bid-tr {
            td {
                vertical-align: top;
                font-size: 13px;
                /*line-height: 1.2em;*/
                padding: 10px 0 10px 5px;
                letter-spacing: 0;
                cursor: pointer;

                &:first-child {
                    position: relative;
                    padding-left: 18px;
                }
            }
        }
    }
}

.trading-history {
    max-height: 495px;
    overflow-y: auto;
    @media screen and (max-width: 1019px) {
        height: 200px;
    }

    &.left-menu {
        height: 399px !important;
    }

    &.open-position {
        overflow: unset;
        height: 283px;
        overflow-y: auto;
    }

    .close-position {
        width: 100%;
        padding: 0 10px;
        text-align: center;
        cursor: pointer;
    }

    .list {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 15px;
        font-size: 13px;
        line-height: 18px;

        .info-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            span, b {
                display: flex;
            }
        }

        .buy-date,
        .sell-date {
            .date {
                letter-spacing: 0;
            }
        }
    }

    .list-info-box {
        padding: 10px;
    }
}

.market-history,
.trading-history {
    height: 407px;
}

.buy-orders-table {
    .table-head {
        th {
            padding-right: 0 !important;
            padding-left: 0 !important;

            &:not(:first-of-type) {
                width: 35%;
            }

            &:first-of-type {
                width: 30%;
            }
        }
    }

    .sell-buy-bids {
        .bid-tr {
            div {
                &:not(:first-of-type) {
                    width: 35%;
                }

                &:first-of-type {
                    width: 30%;
                    padding-left: 12px !important;
                }

                &:last-of-type {
                    justify-content: flex-end;
                }
            }
        }
    }
}

.sell-orders-table {
    .table-head {
        th {
            padding-right: 0 !important;
            padding-left: 0 !important;

            &:not(:last-of-type) {
                width: 35%;
            }

            &:last-of-type {
                width: 30%;
            }

            &:not(:first-of-type) {
                text-align: right;
            }
        }
    }

    .sell-buy-bids {
        .bid-tr {
            div {
                &:not(:first-of-type) {
                    justify-content: flex-end;
                }

                &:not(:last-of-type) {
                    width: 35%;
                }

                &:last-of-type {
                    width: 30%;
                    padding-right: 12px !important;
                }
            }
        }
    }
}

.mat-tooltip {
    border: 1px solid rgba(204, 191, 53, 0.1);
}

.app-tv-chart-container {
    height: 460px !important;
    margin-bottom: 15px;
}

app-tv-chart-container {
    iframe {
        background: transparent !important;
        height: 470px !important;
        border-radius: 4px;
    }
}


.no-info {
    margin: 10px 0;
    padding: 10px 8px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    font-size: 13px;
    line-height: 15px;
    background-color: transparent;
    text-align: center;
}

.open-orders {
    .no-info {
        margin-top: 5px !important;
        padding: 10px 8px !important;
        border-radius: 3px;
        text-align: center;
        font-size: 13px;
        line-height: 15px;
    }
}

.sell-orders-table, .buy-orders-table {
    .no-info {
        border-color: transparent !important;
        margin: 0 !important;
    }
}


.chart-periods {
    text-align: right;
    margin-right: 10px;
    color: #ffffff;

    ul {
        margin: 0;
        padding: 0;

        li {
            padding: 3px 5px 1px 6px;
            display: inline-block;

            &.active {
                background-color: $main-bg-color-active;
                border-radius: 6px;
                cursor: inherit;
            }

            &:hover {
                background-color: $main-bg-color;
                border-radius: 6px;
                cursor: pointer;
            }
        }
    }
}

.fixed {
    position: fixed;
    top: 74px;
    width: 25% !important;
    padding-right: 22px;
    z-index: 2;
    @media screen and (min-width: 768px) and (max-width: 991px) {
        width: 33.33333333% !important;
    }
    @media screen and (max-width: 767px) {
        width: 100% !important;
    }
}

.currency-select-table {
    overflow: auto;

    > div {
        max-height: 70vh;
        width: 200%;
    }

    .header {
        min-width: 100px;
        padding: 10px;
        text-align: center;
        background-color: #1e1e2b;
        color: #ffffff;
        position: relative;
        border-bottom: 1px solid #6f6f6f;
        font-size: 20px;
        height: 50px;

        img {
            height: 24px;
            position: absolute;
            left: 50%;
            top: 11px;
            margin-left: -55px;
        }
    }

    .list-symbols {
        position: relative;
        min-width: 100px;
        padding: 10px;
        margin: 10px 5px;
        background-color: #343442;
        color: #ffffff;
        cursor: pointer;

        &.active {
            border-left: 2px solid $basic-green;
        }

        &:hover {
            background-color: #1e1e2b;
        }


        .symbol-pair {
            font-size: 18px;
            margin-bottom: 10px;
            text-align: center;

            img {

                margin-right: 4px;
                height: 24px;
            }

            b {
                &.green-change {
                    color: $basic-green;
                }

                float: right;
            }
        }

        .symbol-change, .symbol-volume {
            font-size: 11px;

            &.orders-count {
                .active {
                    z-index: 1000;
                    position: absolute;
                    left: 5px;
                    top: 5px;
                    color: #ffffff;
                    background-color: $basic-red;
                    display: inline-block;
                    width: 13px;
                    height: 13px;
                    line-height: 14px;
                    border-radius: 15px;
                    text-align: center;
                }
            }

            b {
                &.green-change {
                    color: $basic-green;
                }

                float: right;
            }
        }
    }
}

popover-content {
    position: relative;

    .popover-title {
        background-color: #1e1e2b;
        color: #ffffff;
        border-bottom: 1px solid #6f6f6f;
    }

    .popover {
        min-width: 500px;
        padding-bottom: 5px;
        margin-top: -41px !important;
        background-color: #252535;

        &:hover {
            background-color: #3e3e4a;
        }
    }

    .right {
        & > .arrow {
            top: 14% !important;

            &:after {
                border-right-color: #252535 !important;
            }
        }
    }
}

.verification-notification {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    background-color: $basic-red;
    // margin-top: 5px;
    margin-left: 7px;
    margin-right: 10px;
    color: #ffffff !important;
    font-size: 10px;
    line-height: 20px;
    text-align: center;

    &.submitted {
        background-color: #ffa423;
    }

    &.user-panel {
        margin-top: 2px;
    }
}

.main-container-animation {
    -webkit-transition: all 0.3s; /* For Safari 3.1 to 6.0 */
    transition: all 0.3s;
    color: #000;

    .main-app-container {
        position: relative;
    }
}

.sell-buy-bids {
    // max-height: 275px;
    // overflow-y: auto;
    table {
        width: 100%;
    }
}

.pair-selector {
    .ng-dropdown-panel {
        border: 1px solid #23375d !important;
    }

    .ng-dropdown-panel-items {
        max-height: 404px !important;
    }

    .ng-option {
        img {
            height: 14px;
            float: left;
            margin: 3px 6px 0 0;
        }

        &.ng-option-marked {
            background-color: $main-bg-color !important;
        }

        border-bottom: 1px solid $main-bg-color !important;
        position: relative;
        background-color: $main-bg-color-active !important;

        .pair-template {
            color: #ffffff;
        }

        .pair-template-info {
            color: #ffffff !important;
            font-size: 10px !important;
        }
    }

    .ng-dropdown-panel {
        background-color: $main-bg-color !important;
    }

    .symbol-volume {
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: #f92552;
        display: inline-block;
        width: 13px;
        height: 13px;
        line-height: 10px;
        border-radius: 15px;
        text-align: center;

        b {
            font-size: 10px;
            color: #fff;
            margin-left: 2px;
        }
    }
}

.sell-buy-bids .bid-tr td {
    position: relative;
}

.sell-buy-bids {
    span {
        &.sell, &.buy {
            width: 7px;
            height: 7px;
            position: absolute;
            border-radius: 9px;
        }
        &.sell {
            top: 13px;
            right: 1px;
        }
        &.buy {
            top: 13px;
            left: 1px;
        }
    }
}

.sell-buy-bids {
    .bid-tr {
        display: flex;
        flex-flow: row nowrap;

        div {
            display: flex;
            position: relative;
            width: 33.3333%;
            padding: 8px 0 !important;
            justify-content: flex-start;
            align-items: center;
            font-size: 10px;
            line-height: 18px;

            &:first-child {
                padding-left: 15px;
            }
        }
    }
}

.open-position-table {
    .main-page-table-body {
        max-height: 410px;
        overflow-y: auto;
    }

    .open-orders {
        margin: 0 -10px;
    }
}

@media screen and (max-width: 959px) {
    .open-position-table .main-page-table-body {
        height: 200px;
    }
}

.my-position-history .main-page-table-body {
    // height: 200px;
    overflow-y: auto;
}

.my-trading-history {
    min-height: 185px;
}

.my-trading-history .main-page-table-body {
    height: 200px;
    overflow-y: auto;
}

.open-position-container {
    opacity: 0
}

.main-app-container {
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid;
    box-shadow: 0 0 5px 0 rgba(142, 139, 133, 0.32);
    transition: background-color .3s ease;
}

.order-info-tooltip {
    white-space: pre-line;
}

.chart-container {
    padding: 5px;
}
