.new-pair-selector-container {
    position: relative;
    display: flex;
    height: 64px;
    flex: 1 1 auto;
    .main-table-head {
        th {
            @media #{$screen-lte-480px} {
                font-size: 10px !important;
            }
        }
    }
    .main-table-row {
        @media #{$screen-lte-480px} {
            font-size: 10px !important;
        }
    }

    .pair-selection-expansion {
        position: absolute;
        z-index: 10;
        .pairs-mat-expansion-panel {
            height: 64px !important;
            overflow: visible;
        }
        .mat-expansion-panel {
            position: relative;
            height: 100%;
        }
        .mat-expansion-panel:not([class*=mat-elevation-z]) {
            box-shadow: unset;
        }
        mat-expansion-panel-header,
        .mat-expansion-panel-header {
            height: 64px !important;
            .mat-content {
                height: 64px !important;
            }
        }
        .mat-expansion-indicator {
            display: flex;
            margin-bottom: 2px;
        }
        .mat-expansion-panel-content {
            position: absolute;
            top: 74px;
            min-width: 700px;
            background-color: white;
            border-radius: 4px;
            @media #{$screen-lte-1600px} {
                min-width: unset;
                width: 450px;
            }
            @media #{$screen-lte-479px} {
                min-width: unset;
                width: 350px;
            }
        }
    }
    .selector-icon {
        height: 18px;
        i {
            font-size: 14px;
            transform: rotate(90deg);
        }
    }
    .sort-arrow {
        float: right;
        path {
            transition: fill .2s ease;
        }
    }
    .pairs-list-container {
        width: auto;
        height: 700px;
        color: #ffffff;
        font-size: 12px;
        padding: 10px;
        margin-bottom: unset;
        @media screen and (max-height: 900px) {
            height: 365px;
        }
        .responsive-table {
            max-height: 625px;
            margin: unset;
            @media screen and (max-height: 900px) {
                max-height: 298px;
            }
            .main-table-row td {
                letter-spacing: 0;
            }
        }
        .favorites {
            text-align: right;
            line-height: 5px;
            i {
                font-size: 14px;
            }
        }

        .show-favorite {
            line-height: 1em;
            cursor: pointer;
        }

        .select {
            color: #ffffff;
            padding: 8px 10px;
            height: 32px;
            background-color: $main-bg-color-active;
            border-radius: 3px;
            border: 0 solid #000000;
        }

        .main-table-row {
            td:first-child {
                text-align: left;
            }
            &:hover {
                // background-color: $main-bg-color-active;
                cursor: pointer;
            }
            .icon {
                width: 13px;
                float: left;
                margin-right: 5px;
            }
            td {
                height: 30px;
                padding: 10px 5px;
            }
        }
    }
}

.new-pair-selector {
    position: relative;
    display: flex;
    padding: .75rem .75rem;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
