app-notifications {
    display: flex;
    align-items: center;
}

.notifications-container {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    min-height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.notify-toggler {
    display: flex;
    flex: 1 1 20px;
    height: 50px;
    min-width: 50px !important;
    padding: unset !important;
    border-radius: 30px !important;
    justify-content: center;
    align-items: center;

    .mat-button-wrapper,
    .notify-icon {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: center;
    }
    .ring-icon {
        @include colorize(color, infoIcon);
    }
}

.notify-icon {
    position: relative;
    min-width: 30px;
    height: 30px;
}

.notification-badge {
    position: absolute;
    top: 5px;
    left: calc(100% - 8px);
    content: '';
    display: flex;
    min-width: 19px;
    height: 19px;
    padding: 0 5px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    @include colorize(background-color, infoBadgeBg);
    @include colorize(color, infoBadgeTxt);
}

.notify-list-box {
    position: absolute;
    top: 50px;
    display: none;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    width: 340px;
    max-height: 440px;
    border-radius: 4px;
    overflow: hidden;
    @include colorize(background-color, infoListBg);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
                0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                0px 1px 10px 0px rgba(0, 0, 0, 0.12);

    &.opened {
        display: flex;
    }
}

.notify-list-footer {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    max-height: 60px;
    min-height: 50px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
}

.notify-list {
    display: flex;
    flex-flow: column nowrap;
    max-width: 100%;
    max-height: 390px;
    padding-top: 10px;
    overflow-y: auto;
}

.notify-list-item {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    max-width: 100%;
    height: 76px;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    .item-wrapper {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        flex: 1 1 100%;
        max-width: 100%;
        height: 100%;
        padding: 0 16px;
        justify-content: flex-start;
        align-items: center;

        &.as-read {
            &:after {
                display: block;
            }
        }

        &:after {
            position: absolute;
            right: 16px;
            content: '';
            display: none;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            @include colorize(background-color, infoActiveBullet);
        }
    }

    &:hover {
        .item-wrapper {
            @include colorize(background-color, infoListItemHoverBg);
        }
        .notify-actions-button {
            display: flex !important;
        }
    }
}

.notify-avatar-wrapper {
    display: flex;
    flex: 0 1 32px;
    min-width: 32px;
    height: 32px;
    margin: 0;
    border-radius: 24px;
    overflow: hidden;
    justify-content: center;
    align-items: center;

    .initials {
        display: flex;
        width: 36px;
        height: 36px;
        transform: scale3d(1.2, 1.2, 1.2);
        justify-content: center;
        align-items: center;
        font-family: "Poppins Medium", sans-serif;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0;
        text-transform: uppercase;
        @include colorize(color, infoInitials);
    }
}

.notify-text-box {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    padding: 10px 0;
    margin: 0 26px 0 12px;
    font-family: 'Poppins Medium', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;

    &.as-read {
        b, .bold {
            @include colorize(color, infoActiveTxt);
        }
    }

    .text {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break: break-word;
        @include colorize(color, infoTxt);
    }

    b, .bold {
        font-family: 'Poppins Bold', sans-serif;
        font-weight: 700;
    }

    .date {
        font-size: 10px;
        line-height: 1.4;
        &.as-read {
            @include colorize(color, infoActiveDate);
        }
    }
}

.notify-actions-button {
    position: absolute !important;
    right: 30px;
    display: none !important;
    flex-flow: row nowrap;
    height: 30px;
    width: 30px;
    min-width: 30px !important;
    padding: 0 !important;
    align-items: center;
    justify-content: center;
    border-radius: 30px !important;
    @include colorize(background-color, infoActionsBtn, !important);
    @include colorize(color, infogActionsTxt, !important);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;

    .mat-button:hover .mat-button-focus-overlay,
    .mat-stroked-button:hover .mat-button-focus-overlay {
        opacity: 0;
    }

    &[aria-expanded="true"] {
        display: flex !important;
    }
}

.mark-all {
    border: unset;
    @include colorize(background-color, infoMarkAllBg, !important);
    @include colorize(color, infoMarkAll, !important);
    text-transform: uppercase;
    cursor: pointer;

    &[disabled] {
        @include colorize(color, infoDisabledMarkAll, !important);
    }
}
