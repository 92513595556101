.ng-select {
  input{
    color: #ffffff;
  }
  margin-top: 3px;
  cursor: pointer;
  .ng-select-container{
    background-color: $main-bg-color-active !important;
    border: 1px solid $main-bg-color-active !important;
  }
  .ng-value{
    color: #ffffff !important;
  }
}

* {
    ::-webkit-scrollbar {
        width: 2px !important;
    }
    ::-webkit-scrollbar-button {
        background-repeat:no-repeat !important;
        width:2px !important;
        height:0 !important;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:horizontal {
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }
}

::-webkit-resizer {
  width:4px;
  height:0;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-button:horizontal {
    background-repeat:no-repeat;
    height:5px;
}

::-webkit-resizer:horizontal {
    height:4px;
}

::-webkit-scrollbar:horizontal {
    height: 4px;
}

.symbol-info {
    display: flex;
    flex-flow: column nowrap;
    padding: 4px 0;
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    @media only screen and (max-width: 1199px) {
        font-size: .9em;
        .positive-change,
        .negative-change,
        .value {
            font-size: .85em;
            letter-spacing: 0;
        }
    }
}

.buy-checkbox{
  .mat-radio-container{
    .mat-radio-outer-circle{
      border-color: $basic-blue !important;
    }

    .mat-radio-inner-circle{
      background-color: $basic-blue !important;
    }

    .mat-radio-outer-circle {
      border-color: $basic-blue !important;
    }
  }
}

.sell-checkbox{
  .mat-radio-container{
    .mat-radio-outer-circle{
      border-color: $basic-blue !important;
    }

    .mat-radio-inner-circle{
      background-color: $basic-blue !important;
    }

    .mat-radio-outer-circle {
      border-color: $basic-blue !important;
    }
  }
}

.mat-radio-label-content{
    @include themify($themes) {
        border-color: themed(mainTxt);
    }
}

.mat-form-field {
    &.show-label {
        .mat-form-field-label {
            overflow: unset !important;
        }
    }
}

.mat-form-field-infix{
  color: #ffffff !important;
  span {
      width: 100%;
  }
}
.input-title {
    margin-bottom: 10px !important;
}
.egret-indigo .mat-form-field-underline{
  background-color: #99b0db4f !important;
}

snack-bar-container{
  transform: translateY(0%) !important;
  position: absolute !important;
  left: 1.5% !important;
  bottom: 10px !important;
  width: 97% !important;
  max-width: 97% !important;
  padding: 20px !important;
}


.info-snackbar{
  display: inline-block;
  margin-right: 25px;
  margin-left: 20px;
    @media #{$screen-lte-850px} {
        margin-left: 0;
    }
  .title{
    font-weight: bold;
  }
  div{
    display: inline-block;
  }
}


.egret-indigo .mat-input-element:disabled{
  color: #ffffff !important;
}

/* Add product image uploading */

.uploaded-images-container {
  // background-color: $body-bg;
  position: relative;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
}

.uploaded-documents {
    &.downloadable {
        cursor: pointer;
    }
}

.product-uploaded-image-remove-button {
  border: 1px solid #ffffff;
  position: absolute;
  right: -8px;
  top: -8px;
  height: 30px;
  width: 30px;
  border-radius: 18px;
  background-color: #68cbcc;
  box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
  @media #{$screen-lt-md} {
    right: -5px;
    top: -5px;
    height: 19px;
    width: 19px;
  }
  span.icon-close {
    color: #ff6163;
    font-size: 10px;
    @media #{$screen-lt-md} {
      font-size: 8px;
      line-height: 19px;
    }
  }
}
.product-uploaded-image-wrapper {
  height: 150px;
  width: 150px;
  overflow: hidden;
    @media #{$screen-lt-sm} {
        width: 120px;
        height: 120px;
    }
    &.for-user-view {
        .download-link {
            display: inline-block;
            height: 100%;
            width: 100%;
            padding: 0 !important;
        }
    }
  .product-uploaded-image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-radius: 8px;
  }
}
.downloaded-image-box {
    margin-top: 15px;
}
.button{
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}

.upload-button{
  background-color: #68cbcc;
  color: $main-bg-color;
  width: 100%;
  border-radius: 0;
  padding: 12px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}

app-upload-dialog{
  margin-bottom: 15px;
}

.new-design-btn-wrapper {
    display: flex;
    flex-flow: row nowrap;
    margin-right: 20px;
    justify-content: center;
    align-items: center;

    @media #{$screen-lte-480px} {
        display: none;
    }
}

.button-new-design {
    display: flex;
    height: 32px !important;
    border: 1px solid transparent !important;
    line-height: 1 !important;
    letter-spacing: 0;
    text-transform: uppercase !important;
    @include colorize(background-color, newDesignBtnBg, important);
    @include colorize(color, newDesignBtn, important);

    &, &:hover {
        text-decoration: none !important;
    }
    &, &.active {
        outline: 0 !important;
    }

    &.mat-raised-button {
        padding: 8px 20px !important;
        border-radius: 22px !important;
    }
}

.uploaded-images-list-container {
  .uploaded-image-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 0 10px;
  }

  .uploaded-image-remove-button {
    border:1px solid #cccccc;
    position: absolute;
    right: -8px;
    top: -8px;
    height: 26px;
    width: 26px;
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: 0 7px 18px 0 rgba(0, 0, 0, 0.08);
    margin: 0 !important;
    padding: 0 !important;
    line-height: 9px;
    i{
        font-size: 16px;
    }
    span.icon-close {
      color: #ff6163;
      font-size: 10px;
    }
  }
  .uploaded-image-wrapper {
    height: 100px;
    width: 100px;
    overflow: hidden;
  }
  .uploaded-image {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border-radius: 8px;
    border: 1px solid #303045;
  }
}

.text-align-left{
  text-align: left;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.estimated-amount,
.estimated-fee {
    font-size: 13px;
    line-height: 15px;
}
.help-box {
    position: relative;
    display: flex;
    align-items: center;
    &:hover {
        .fees-tooltip {
            display: block;
        }
    }
    .help-icon {
        margin-left: 5px;
    }
    .fees-tooltip {
        position: absolute;
        display: none;
        padding: 15px 10px;
        top: 25px;
        left: 0;
        width: 250px;
        border-radius: 8px;
        color: white;
        opacity: .9;
        z-index: 20;
        .title-top {
            margin-bottom: 10px !important;
            font-size: 14px;
            font-family: 'Poppins Bold', sans-serif;
        }
        .section {
            margin-bottom: 10px;
            font-size: 10px;
            line-height: 15px;
            .title {
                font-size: 12px;
                font-family: 'Poppins Bold', sans-serif;
            }
            span {
                font-family: 'Poppins Bold', sans-serif;
            }
        }
    }
}
.ng-select.ng-select-opened .ng-placeholder {
  display: block !important;
}

.ng-select.ng-select-filtered .ng-placeholder {
  display: none !important;
}

.ng-select.ng-select-opened .ng-value {
  display: none !important;
}

.mat-paginator-range-label {
    display: none !important;
}

.mat-paginator-page-size-label {
    display: none !important;
}

.snackbar-title{
    text-transform: capitalize;
    color: $basic-green;
}
.advanced-options {
    font-size: 13px;
    line-height: 15px;
    cursor: pointer;
    i {
        font-size: 12px;
    }
}
.main-exchange {
    .main-open-orders {
        max-height: 250px;
        @media only screen and (max-width: 991px) {
            max-height: unset;
        }
        .main {
            padding: 0 5px;
        }
    }
}

.selected-symbol-info {
    .selected-symbol-container {
        background-color: $main-bg-color-active;
        margin: 6px 4px 4px;
        border-radius: 3px;
        color: #ffffff;
        padding: 7px 0;
    }
    .title {
        font-size: 10px;
        margin-bottom: 3px;
        letter-spacing: 0;
    }
    .value {
        font-size: 12px;
        color: $basic-green;
        letter-spacing: 0;
    }
}

.white{
    color: #ffffff;
}

.img-thumbnail{
    webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
}

.current-page {
    float: right;
    color: #ffffff;
    line-height: 58px;
    font-size: 12px;
    margin-right: 5px;
}

.go-to-first-page{
    margin-right: 15px;
    text-decoration: none;
    cursor: pointer;
}

.go-to-first-page:hover {
    text-decoration: none;
}

.mat-checkbox-label {
    color: #ffffff;
}

.mat-paginator-navigation-previous:disabled {
    opacity: 0.2;
}

.mat-paginator-navigation-next:disabled {
    opacity: 0.2;
}

.leverage-selector {
    >div {
        float: left;
        color: #67757c;
        background-color: $main-bg-color;
        padding: 2px 5px;
        border-radius: 2px;
        &.active {
            background-color: #c9c4c4;
            &:hover {
                background-color: #c9c4c4 !important;
            }
        }
        &.selectable {
            padding: 2px 7px;
            cursor: pointer;
            border: 1px solid $main-bg-color-active;
            &:hover {
                background-color: #c9c4c4;
            }
        }
    }
}
