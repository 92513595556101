.table{
    &.font-small{
        .table-header{
            font-size: 10px !important;
        }
        .table-row{
            font-size: 10px !important;
        }
    }

    &.font-normal{
        .table-header{
            font-size: 13px !important;
        }
        .table-row{
            font-size: 13px !important;
            @media #{$screen-lt-lg} {
                letter-spacing: normal;
            }
        }
    }

    &.middle-media-font {
        @media screen and (min-width: 960px) and (max-width: 1100px) {
            .table-header {
                font-size: 10px !important;
            }
            .table-body .table-row {
                font-size: 9px !important;
            }
        }
    }

    &.button-include {
        .table-header{
            line-height: 24px;
        }
        .table-row{
            line-height: 24px;
        }
        .table-col {
            @media #{$screen-lt-md} {
                &:last-of-type {
                    justify-content: flex-end;
                }
                b { display: flex; }
            }
        }
    }

    &.no-spacing {
        .table-row .table-col {
            letter-spacing: 0 !important;
        }
    }
    .table-header{
        background-color: transparent;
        margin: 10px 0;
        padding: 10px;
        font-family: "Poppins Medium", sans-serif !important;
        font-weight: bold;
        font-size: 13px;
        .table-col{
            line-height: 12px;
            word-break: break-word;
            font-weight: bold;
            @media #{$screen-lte-1200px} {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    .table-body {
        .table-row {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            margin: 0 10px;
            padding: 8px 4px;
            color: #ffffff;
            font-size: 13px;
            &:last-of-type {
                border-bottom: unset !important;
            }
            &.hidden {
                display: none !important;
            }
        }
        .table-col{
            display: flex;
            align-items: center;
            //justify-content: flex-start;
            word-break: break-word;
            line-height: 1.4em;
            b {
                display: none;
            }
            @media #{$screen-lt-md} {
                justify-content: space-between;
                &.flex-end {
                    justify-content: flex-end;
                }
                b {
                    display: flex;
                }
            }
            span.estimated {
                font-size: 10px;
                @media #{$screen-lte-1200px} {
                    font-size: 8px;
                }
            }
        }
        .wallet-info{
            display: none !important;
            flex-flow: row wrap;
            &.show{
                display: flex !important;
                margin-top: 10px;
            }
        }
        .table-row {
            b{
                float: left;
            }
            .currency-image{
                img{
                    width: 15px;
                    margin-right: 10px;
                }
            }
            @media #{$screen-lt-md} {
                .table-col{
                    padding: 4px 0;
                    line-height: 20px;
                    text-align: right;
                    &.with-button {
                        padding-right: 2px;
                    }
                    &.deposit-withdraw {
                        padding-bottom: unset;
                        line-height: 14px;
                        b { line-height: 22px; }
                    }
                }
                .table-col:last-child {
                    border-bottom: unset;
                }
            }
            &.column {
                .table-col {
                    padding: 4px 0;
                    border-bottom: 1px solid $wallets-table-border;
                    line-height: 20px;
                    text-align: right;
                    &.no-aligned {
                        text-align: unset;
                    }
                    &.with-button {
                        padding-right: 2px;
                    }
                }
                .table-col:last-child {
                    border-bottom: unset;
                }
            }
        }
        .text-right {
            text-align: right;
        }
        .sell{
            b{
                color: #ffffff;
            }
            color: $basic-red;
        }
        .buy{
            b{
                color: #ffffff;
            }
            color: $basic-green;
        }
        .long{
            b{
                color: #ffffff;
            }
            color: $basic-green;
        }
        .short{
            b{
                color: #ffffff;
            }
            color: $basic-red;
        }
        .material-icons {
            cursor: pointer;
            font-size: 18px;
            &:hover {
                color: #cccccc;
            }
            &.deposit {
                color: $basic-green;
                &:hover{
                    color: #5fdb17;
                }
            }
            &.withdraw {
                color: $basic-red;
                &:hover{
                    color: #e63c5a;
                }
            }
            &.deposit, &.withdraw {
                font-size: 23px;
                line-height: 1rem;
                transform: translateY(8px);
                @media #{$screen-lt-md} {
                    line-height: 23px;
                    transform: unset;
                }
            }
        }
        .wallet-info{
            position: relative;
            display: none !important;
            width: 100%;
            padding: 10px;
            // background-color: $main-bg-color-active;
            border-radius: 3px;
            // color: #ffffff;
            .close-block{
                position: absolute;
                right: 10px;
                top: 10px;
                z-index: 1000000;
            }
        }
    }
}


.main-page-table{
    font-size: 11px;
    overflow-y: auto;
    .main-page-table-header {
        border-bottom: 1px solid;
        font-size: 11px;
        > div,
        .main-table-head-row {
            padding: 8px 5px;
            font-size: 1em;
            font-family: "Poppins Medium", sans-serif !important;
            font-weight: bold;
            line-height: 18px;
            letter-spacing: 0;
        }
    }
    .main-page-table-body {
        font-size: 9px;
        .table-row {
            padding: 5px 5px;
            border-bottom: 1px solid;
            &.no-spacing {
                letter-spacing: 0;
            }
            &:last-of-type {
                border-bottom: unset !important;
            }
            b { float: left; }
            @media #{$screen-lt-md} {
                margin-bottom: 17px;
                .table-col {
                    text-align: right;
                    border-bottom: 1px solid;
                    &.with-button {
                        padding: 10px 2px;
                    }
                }
                .table-col:last-child {
                    border-bottom: unset;
                    padding-top: 10px;
                }
            }
        }
        .main-table-row {
            padding: 5px 5px;
            font-size: 1.1em;
            line-height: 18px;
            .date {
                font-size: 1em;
                letter-spacing: 0;
            }
        }
    }
}
.oo-cancel-button {
    padding: 0 5px !important;
    font-size: 1em;
    line-height: 28px !important;
}
.table-title {
    padding: 10px;
    font-size: 18px;
    color: #ffffff;
}

app-admin-margin-tradings {
    @media screen and (min-width: 960px) and (max-width: 991px) {
        .container {
            width: 970px;
        }
    }
}

.position-history-table {
    .table-body {
        .table-row {
            margin: 0 10px 10px;
            font-size: 10px;
        }
        .table-col {
            @media #{$screen-gt-sm} {
                &:last-of-type:last-child {
                    text-align: unset;
                }
            }
        }
    }
}
.app-position-table {
    width: 100%;
}
.position-history-row,
.position-table-row {
    .table-col {
        flex-grow: 1;
        flex-shrink: 1;
        &:nth-child(1) {
            max-width: 10%;
        }
        &:nth-child(2), &:nth-child(3),
        &:nth-child(4), &:nth-child(5),
        &:nth-child(6), &:nth-child(7) {
            max-width: 14%;
        }
        &:not(:nth-child(1)) {
            margin-left: 1%;
        }
        @media #{$screen-lt-md} {
            max-width: 100% !important;
            &:not(:nth-child(1)) {
                margin-left: 0;
            }
        }
    }
    &.admin-view {
        .table-col {
            flex-grow: 1;
            flex-shrink: 1;
            &:nth-child(1) { max-width: 14%; }
            &:nth-child(2) { max-width: 6%; }
            &:nth-child(3), &:nth-child(4),
            &:nth-child(5), &:nth-child(6),
            &:nth-child(8) {
                max-width: 10%;
            }
            &:nth-child(7) {
                max-width: 8%;
            }
            &:nth-child(9) {
                max-width: 9.5%;
            }
            &:nth-child(10) {
                max-width: 8%;
        }
            &:not(:nth-child(1)) {
                margin-left: .5%;
            }
            @media #{$screen-lt-md} {
                max-width: 100% !important;
                &:not(:nth-child(1)) {
                    margin-left: 0;
                }
            }
        }
    }
}

.position-table-row.admin-view {
    .table-col {
        line-height: 16px;
    }
}

.warning-text {
    @include themify($themes) {
        color: themed(mainTxt);
        border: 1px solid themed(warningBorder);
        border-radius: 5px;
    }
    padding: 10px;
}

.txid-col {
    button.mat-icon-button {
        mat-icon.text-copy-icon {
            font-size: 12px;
        }
    }
    .mat-icon-button {
        height: 20px;
        width: 20px;
        line-height: 20px;
    }
    @media #{$screen-lt-md} {
        mat-icon.text-copy-icon {
            height: 12px;
            line-height: 12px;
        }
        .mat-icon-button {
            height: 12px;
            width: 16px;
            line-height: 12px;
        }
    }
}


.vertical-table {
    .table-row {
        &:hover {
            background-color: transparent !important;
        }
    }
    .table-col {
        b {
            display: block !important;
            float: right !important;
            margin-right: auto;
        }
    }
}
