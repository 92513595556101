.maintenance{
    text-align: center;
    color: #ffffff;
    padding: 5px;
    position: relative;
    &.notification {
        background-color: $basic-green;
    }
    &.active {
        background-color: $basic-red;
    }
    img {
        max-height: 25px;
    }
    .close-maintenance{
        position: absolute;
        right: 5px;
        top: 3px;
        cursor: pointer;
        i{
            font-size: 21px;
        }
        &:hover{
            color: #1c2030;
        }
    }
}

.maintenance-table {
    &.table-body .table-row {
        .table-col {
            &:last-of-type {
                button {
                    @media #{$screen-lt-md} {
                        margin-left: 10px !important;
                    }
                }
            }
        }
    }
    span{
        &.color-maintenance{
            color: $basic-red;
        }
        &.color-notification{
            color: $basic-green;
        }
    }
}
