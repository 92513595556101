.meta-trader-h2 {
    font-size: 14px;
    padding: 15px;
    color: #ffffff;
}

.mt5 .table .table-body .table-row{
    background-color: #162648;
    padding: 5px 10px;
}

.mt5 mat-paginator{
    background-color: #23375d !important;
}
.mt5-account-details-container {
    .verification-button {
        height: 24px;
        line-height: 24px;
    }
    .regenerate-btn {
        margin-top: 10px;
    }
    @media #{$screen-lt-md} {
        font-size: .8em;
    }
    @media #{$screen-xs} {
        font-size: .6em;
        .verification-button {
            padding: 0 8px !important;
            font-size: 10px !important;
        }
    }
}
.deposit-withdraw-container {
    button {
        &.deposit-btn {
            margin-right: 10px;
            @media #{$screen-lt-md} {
                margin: 10px 0 !important;
            }
        }
    }
}
