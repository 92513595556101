mat-form-field {
    margin-bottom: 10px;
}

.mat-form-field-wrapper,
.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding-bottom: unset;
}
.mat-form-field-infix,
.mat-form-field-appearance-legacy  .mat-form-field-infix {
    display: flex;
    flex: 1 1 auto;
    padding: 10px 8px 4px 8px!important;
    border-top: unset;
    align-items: center;
}
.mat-form-field-underline {
    display: none;
}
.mat-select,
.mat-input-element {
    display: flex;
    flex: 1 0 100%;
    max-width: 100%;
    height: 25px;
}
.mat-selector-option {
    &.hidden {
        display: none !important;
    }
}
.mat-select-trigger {
    padding-top: 6px;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    top: 20px;
    left: 8px;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float {
    &.mat-form-field-should-float .mat-form-field-label,
    .mat-input-server:focus + .mat-form-field-wrapper .mat-form-field-label {
        display: inline-block;
        width: auto;
        margin-bottom: 2px;
        padding: 0 5px;
        transition: background-color .3s ease;
        -webkit-transform: translateY(-1.28125em) scale(0.9);
        -ms-transform: translateY(-1.28125em) scale(0.9);
        transform: translateY(-1.28125em) scale(0.9);
    }
}

.mat-expansion-panel {
    background: transparent !important;
}
.mat-expansion-indicator {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    margin: 0 4px;
    &:after {
        content: none !important;
    }
}
mat-expansion-panel-header,
.mat-expansion-panel-header {
    &:not([aria-disabled='true']):hover {
        background: unset !important;
    }
}
.mat-expansion-panel-body {
    padding: 0 !important;
}
.mat-raised-button {
    border-radius: 5px !important;
}
.mat-raised-button:not([class*=mat-elevation-z]) {
    box-shadow: none !important;
}
.mat-raised-button[disabled][disabled] {
    background-color: unset;
    color: unset;
    opacity: .4;
}
.mat-select-panel {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}
.mat-select-search-clear {
    right: 0 !important;
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
    line-height: 20px !important;
}
textarea.mat-input-element {
    padding: 6px 0!important;
    &.no-resize {
        resize: none !important;
    }
}

.advanced-options-expansion {
    .mat-expansion-panel:not([class*=mat-elevation-z]) {
        box-shadow: unset !important;
    }
    .mat-expansion-panel-header {
        padding: unset;
        .mat-content {
            flex: 0 1 auto;
        }
    }
}

.mat-snack-bar-container {
    display: flex !important;
    margin: unset !important;
    transition: background-color .3s ease;
}
.mat-simple-snackbar {
    display: flex !important;
    flex: 1 1 100% !important;
    max-width: 100% !important;
    justify-content: space-between;
    align-items: center;
}

.new-pair-selector-container {
    .mat-expansion-panel-header-description, .mat-expansion-panel-header-title {
        @media #{$screen-lte-669px} {
            margin-right: 0 !important;
            font-size: 11px;
        }
    }

    .mat-expansion-panel-header {
        @media #{$screen-lte-669px} {
            padding: 0 !important;
        }
    }
}

.mat-tab-group {
    font-family: 'Poppins Light', sans-serif;
}
