.datatable {
    text-align: left;
    width: calc(100% - 60px);
    margin-left: 30px;
    color: white;
    tr:hover td {
        background-color: rgb(204, 204, 204);
    }

    .pagination{
      margin: 0 1%;
      width: 98%;
      mat-paginator{
        float: right;
        .mat-paginator-decrement{
          border-color: rgba(255, 255, 255, 0.38);
        }
        .mat-paginator-increment{
          border-color: rgba(255, 255, 255, 0.38);
        }
      }
    }

 }

.dashboard-filter{
    font-size: 13px;
}

.admin-user-management-container {
  .datatable{
    font-size: 12px;
    tr{
      th{
        padding: 10px;
      }
    }
  }
    .mat-form-field-underline {
    background-color: #99b0db4f !important;
  }
    .mat-raised-button {
    line-height: 26px;
    padding: 0 10px;
    /*margin: 8px 0;*/
    font-size: 12px;
        .mat-icon {
      font-size: 17px;
      line-height: 26px;
    }
  }
    .table-data:nth-child(even) {
    background-color: $main-bg-color-active;
  }

    table {
        .table-data {
            td {
        padding: 10px;
      }
            td:first-child {
        padding-left: 12px;
      }
    }
  }
 .mat-tab-label {
    min-width: 130px;
    opacity: 1 !important;
 }
    app-kyc {
        .container {
            width: 100%;
        }
    }
}
.edit-button,
.cancel-button,
.approve-button,
.reject-button {
    height: auto !important;
    padding: 0 6px !important;
    border-width: 1px !important;
    border-style: solid !important;
    font-size: 11px;
    line-height: 26px !important;
    letter-spacing: 0;
    background-color: transparent !important;
    text-transform: uppercase !important;
}
.cancel-button {
    padding: 0 5px !important;
}
/*.button-red{
  float: right;
  background-color: #314456 !important;
  border: 1px solid $basic-red !important;
  color: #ffffff !important;
}
*/

/*.button-blue{
  float: right;
  color: #ffffff !important;
  background-color: #314456 !important;
  border: 1px solid #8bfafc !important;
}*/

.button-light-blue{
  float: right;
  background-color: $main-bg-color-active !important;
  color: #ffffff !important;
}

.mat-tab-body-content{
  overflow: hidden !important;
}

/*.attached-documents{
    @include themify($themes) {
        border-color: themed(mainTxt);
    }
  font-size: 18px;
  margin: 10px 0 20px;
}*/

.additional-info{
    @include themify($themes) {
        color: themed(mainTxt);
    }
  .info-row {
    margin-bottom: 15px;
      &.small-btm-margin {
          margin-bottom: 6px;
      }
      &.big-btm-margin {
          margin-bottom: 20px;
      }
      &.no-margin {
          margin: unset !important;
      }
      &.big-top-margin {
          margin-top: 10px;
      }
      .title {
          margin-right: 5px !important;
      }
      .value {
          font-family: 'Poppins Medium', sans-serif !important;
          font-weight: 700;
      }
  }
}

.verified-status {
/*  line-height: 28px;
  text-align: right;
  width: 100%;
  margin-bottom: 15px;*/
}

.users-page-table-wrapper{
  padding-top: 30px;
  padding-bottom: 30px;
}

.payouts-table{
  .row{
    >div {
      line-height: 24px;
    }
  }
}

.complete-info {
    padding: 0 15px;
    margin-bottom: 20px;
}

.statistics-info {
    padding-top: 15px;
    padding-bottom: 15px;
    .no-data-box {
        margin: 0 15px;
    }
  .col-header {
    margin-bottom: 25px;
    padding: 0 10px;
    font-size: 18px;
    .col-header-filter{
      font-size: 14px;
      float: right;
    }
  }

  .chart-title {
      position: static;
      line-height: 22px;
      font-size: 18px;
      margin-bottom: 15px;
    .info-text {
      margin: 0 !important;
      padding-right: 15px;
      padding-left: 15px;
      letter-spacing: 0;
    }
    @media #{$screen-lt-md} {
        font-size: 10px
    }
    .chart-actions-box {
      .export-csv {
          margin-right: unset;
      }
      .buttons {
          padding: 0;
      }
    }
  }

  .col-info {
      padding-left: 10px;
  }
}

.block-title{
  font-size: 18px;
    @include themify($themes) {
        color: themed(mainTxt);
    }
  padding: 15px 10px;
  .action{
    float: right;
  }
}
.position-history-chart-container {
    margin: 10px;
    .position-history-chart {
        width: 100% !important;
        height: 50vh !important;
        @media #{$screen-lt-md} {
            height: auto !important;
        }
    }
}
.line-bottom {
  border-top: 1px solid;
  padding-top: 15px;
  margin-top: 15px;
}

.user-blocked{
  &.active-user{
    color: $basic-green;
    font-size: 18px;
    text-transform: uppercase;
  }

  &.blocked-user{
    color: $basic-red;
    font-size: 18px;
    text-transform: uppercase;
  }
}

.action-modal-header{
    @include themify($themes) {
        border-color: themed(mainTxt);
    }
  font-size:18px;
  margin-bottom: 15px;
}

.subtitle{

}

.group-info-row-container{
  max-height: 140px;
  overflow-x: auto;
}

.fees-container{
  margin-bottom: 10px;
    .group-info-row-header,
    .group-info-row-container {
        min-width: 550px;
    }
    .group-info-row-header,
    .group-info-row {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        > div{
            padding: 4px 6px;
            font-size: 14px;
            line-height: 26px;
        }
    }
  .group-info-row-header {
    font-weight: bold;
  }
  .group-info-row{
    cursor: pointer;
    &:hover{
      opacity: 0.8;
    }
    .action{
      text-align: right;
      padding-right: 7px;
      i{
        font-size: 14px;
      }
    }

    .fee {
      input {
        width: 50%;
        border-width: 1px;
        border-style: solid;
        border-radius: 3px;
        margin-right: 8px;
        outline: none;
          background-color: transparent;
        // color: #000000;
      }
    }
  }
}

.routing-header-menu {
    display: block;
    padding: 20px 0;
    .routing-header-menu-list {
        .menu-link {
            display: inline-block;
            font-size: 14px;
            .link {
                padding: 20px 10px;
                &:hover,
                &.active {
                    text-decoration: none;
                }
            }
        }
    }
}

.content-body {
    margin-top: 15px;
    padding: 0 10px;
    .content{
        margin: 15px;
    }
}


.mat-chip.mat-standard-chip{
    background-color: $body-bg !important;
    color: #ffffff !important;
}

.settings-section {
    @media #{$screen-lt-sm} {
        .header-title {
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
        }
        .verification-button {
            padding: 4px 9px;
            font-size: 14px;
        }
    }
}

.add-currency-field {
    @media #{$screen-xxs} {
        width: 100% !important;
        margin-left: 0 !important;
        max-width: 100% !important;
    }

}

.treadable-action-button {
    display: flex !important;
    float: unset !important;
    justify-content: center;
    line-height: 24px !important;
    min-width: unset !important;
    @media #{$screen-lt-md} {
        max-width: unset !important;
        width: unset !important;
        min-width: 88px !important;
    }
}

.daemonStatus {
  cursor: pointer;
  &:hover {
    text-decoration: underline
  }
  &.active {
    color: $basic-green;
  }
  &.down {
    color: $basic-red;
  }
}

.chain-info {
  height: 200px;
}

.add-new-setting {
    color: #ffffff;
    cursor: pointer;
    &:hover {
        color: $main-bg-color;
    }
    i {
        float: right;
        margin-bottom: 15px;
    }
}

.settings-main-container {
    max-height: 300px;
    padding-right: 10px;
    margin-bottom: 15px;
    overflow-y: scroll;
}

.new-setting-box {
    position: relative;
    min-height: 34px;
    .material-icons {
        position: absolute;
        right: 6px;
        top: 6px;
        font-size: 22px;
        cursor: pointer;
    }
}

.export-balance-csv {
    display: block !important;
    border: 1px !important;
    border-style: solid !important;
    border-radius: 5px !important;
    line-height: 36px;
    text-align: center;
    background-color: transparent !important;
}


.table-col .mat-raised-button {
    min-width: 45px !important;
}

.filtering-box {
    margin-top: 15px;
    @media #{$screen-lt-md} {
        margin-bottom: 15px;
    }
    mat-form-field {
        margin-bottom: 10px;
        @media #{$screen-lt-md} {
            margin-bottom: 10px;
        }
    }
}

.maintenance-form-box {
    margin: 20px 0;
    .maintenance-row {
        margin-bottom: 15px;
        textarea {
            min-height: 56px !important;
            resize: none !important;
        }
    }
}

.totals-info-txt{
    letter-spacing: 1.5px;
    min-width: 21%;
    @media #{$screen-lt-md} {
        width: 100%;
    }

    .totals-value {
        margin-left: 10px!important;
        font-family: "Poppins Medium", sans-serif !important;
    }
}

.statistics-info {
    .info-header {
        font-size: 20px;
    }
    .col-info {
        font-size: 16px;
        line-height: 2;
        @media #{$screen-lt-lg} {
            font-size: 11px;
        }
    }
    .bold-txt {
        font-weight: bold;
    }
}

.bonus-txt {
    font-weight: bold;
}

.order-info {
    .order-info-first-box {
        font-size: 23px;
        margin-bottom: 15px;
    }

    .order-info-second-box {
        max-width: 650px;
        font-size: 16px;
    }
}


